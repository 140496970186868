import { createSlice } from "@reduxjs/toolkit";
import { RETURNREQUEST } from "../helpers/slice.name";

export const returnRequestSlice = createSlice({
    name: RETURNREQUEST,
    initialState: {
        returnRequestLoading: false,
        returnRequest: [],
        returnRequestMessage: "",
        returnRequestError: "",

        getreturnRequestLoading: false,
        getreturnRequestData: [],
        getreturnRequestMessage: "",
        getreturnRequestError: "",

        getreturnOrderLoading: false,
        getreturnOrderData: [],
        getreturnOrderMessage: "",
        getreturnOrderError: "",
    },
    reducers: {
        returnRequestData: (state, action) => {
            state.returnRequestLoading = true;
            state.returnRequestMessage = "";
            state.returnRequestError = "";
        },
        returnRequestSuccess: (state, action) => {
            state.returnRequestLoading = false;
            state.returnRequest = action.payload.data;
            state.returnRequestMessage = action.payload.message;
        },
        returnRequestError: (state, action) => {
            state.returnRequestLoading = false;
            state.returnRequestError = action.payload;
        },
        returnRequestReset: (state, action) => {
            state.returnRequestMessage = "";
            state.returnRequestError = "";
        },

        //get return req
        getReturnRequestData: (state, action) => {
            state.getreturnRequestLoading = true;
            state.getreturnRequestMessage = "";
            state.getreturnRequestError = "";
        },
        getReturnRequestSuccess: (state, action) => {
            state.getreturnRequestLoading = false;
            state.getreturnRequestData = action.payload.data;
            state.getreturnRequestMessage = action.payload.message;
        },
        getReturnRequestError: (state, action) => {
            state.getreturnRequestLoading = false;
            state.getreturnRequestError = action.payload;
        },
        resetRequestResponse: (state) => {
            state.getreturnRequestLoading = false
            state.getreturnRequestData = []
            state.getreturnRequestMessage = ""
            state.getreturnRequestError = ""
        },
        resetRequestReset: (state) => {
            state.getreturnRequestLoading = false
            state.getreturnRequestMessage = ""
            state.getreturnRequestError = ""
        },

        //get return order
        getReturnOrderData: (state, action) => {
            state.getreturnOrderLoading = true;
            state.getreturnOrderMessage = "";
            state.getreturnOrderError = "";
        },
        getReturnOrderSuccess: (state, action) => {
            state.getreturnOrderLoading = false;
            state.getreturnOrderData = action.payload.data;
            state.getreturnOrderMessage = action.payload.message;
        },
        getReturnOrderError: (state, action) => {
            state.getreturnOrderLoading = false;
            state.getreturnOrderError = action.payload;
        },
        resetOrderResponse: (state) => {
            state.getreturnOrderLoading = false
            state.getreturnOrderData = []
            state.getreturnOrderMessage = ""
            state.getreturnOrderError = ""
        }
    }
});

export const { returnRequestData, returnRequestSuccess, returnRequestError,
    returnRequestReset,
    getReturnRequestData,
    getReturnRequestError,
    resetRequestResponse,
    getReturnRequestSuccess,

    getReturnOrderData,
    getReturnOrderError,
    resetOrderResponse,
    getReturnOrderSuccess,
    resetRequestReset
} = returnRequestSlice.actions;


export default returnRequestSlice.reducer;
