import React, { useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';
import TopBar from '../Components/Common/TopBar';
import ProductListCateogry from '../Components/Products/ProductListCateogry';

const Layout = () => {
  const url = useLocation();
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenuClick = () => {
    setMobileMenu(!mobileMenu);
  };

  const hearderMobile = (onClick) => (
    <li className="mobile-menu-trigger" onClick={onClick}>
      <span></span>
    </li>
  );

  const urls = [
    {
      pathname: '/home',
      // pathname: '/',
    }, {
      pathname: '/short-videos',
    },
    {
      pathname: '/order-conformation',
    }
  ]

  const currentPath = window.location.pathname;
  const shouldHide = urls.some(url => url.pathname === currentPath);

  return (
    <>
      {/* {url.pathname === '/' && <TopBar />} */}
      {url.pathname === '/home' && <TopBar />}
      <div className='sticky-mega' id="header-section">
        <Header hearderMobile={hearderMobile} onClick={handleMobileMenuClick} />
        {/* {!shouldHide && (
          <ProductListCateogry mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
        ) : ''} */}
      </div>
      <main>
        {!shouldHide && (
          <ProductListCateogry mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
        )}
        <Outlet />
      </main>
      {url.pathname !== '/short-videos' && (
        <Footer />
      )}
    </>
  )
}

export default Layout