import { createSlice } from "@reduxjs/toolkit";
import { CHAT } from "../helpers/slice.name";

export const chatSlice = createSlice({
  name: CHAT,
  initialState: {
    chatRecieverLoading: false,
    chatReciever: [],
    chatRecieverMessage: "",
    chatRecieverError: "",

    sendLoading: false,
    sendchat: [],
    sendMessage: "",
    sendError: "",

    getLoading: false,
    getchat: [],
    getMessage: "",
    getError: "",
  },

  reducers: {
    chatRecieverRequest: (state, action) => {
      state.chatRecieverLoading = true;
      state.chatRecieverMessage = "";
      state.chatRecieverError = "";
    },
    chatRecieverSuccess: (state, action) => {
      state.chatRecieverLoading = false;
      state.chatReciever = action.payload.data;
      state.chatRecieverMessage = action.payload.message;
    },
    chatRecieverError: (state, action) => {
      state.chatRecieverLoading = false;
      state.chatRecieverError = action.payload;
    },




    sendRequest: (state, action) => {
      state.sendLoading = true;
      state.sendMessage = "";
      state.sendError = "";
    },
    sendSuccess: (state, action) => {
      state.sendLoading = false;
      state.sendchat = action.payload;
      state.sendMessage = action.payload.message;
    },
    sendError: (state, action) => {
      state.sendLoading = false;
      state.sendError = action.payload;
    },




    getRequest: (state, action) => {
      state.getLoading = true;
      state.getMessage = "";
      state.getError = "";
    },
    getSuccess: (state, action) => {
      state.getLoading = false;
      state.getchat = action.payload.data;
      state.getMessage = action.payload.message;
    },
    getError: (state, action) => {
      state.getLoading = false;
      state.getError = action.payload;
    },
  }
});

export const { chatRecieverRequest, chatRecieverSuccess, chatRecieverError, sendRequest, sendSuccess, sendError, getRequest, getSuccess, getError } = chatSlice.actions;


export default chatSlice.reducer;
