import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { city_id, country_id, phone_number, postal_code, state_id, validAddress } from '../../../helpers/yup.validation.schema';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { AddUserAddress, fetchAddress, fetchCities, fetchCountries, fetchStates } from '../../../middlewares/UserProfile/address';
import LocalstorageService from '../../../helpers/localstorage-services';
import ToastService from '../../../helpers/toast-services';
import { addAddressReset } from '../../../slices/addressSlice';


const AddressForm = ({ show, onHide }) => {
    const dispatch = useDispatch();
    const { countries, cities, states, addAddressMessage, addAddressLoading, addAddress } = useSelector((state) => state.address);
    const userData = LocalstorageService.getLoggedInUserDetails();

    useEffect(() => {
        if (addAddressMessage) {
            ToastService.success(addAddressMessage);
            onHide()
            dispatch(fetchAddress());
            dispatch(addAddressReset());
        }
    }, [addAddressMessage]);

    const handleSubmit = (values, { resetForm }) => {
        const payload = {
            phone_code: values?.phone_code,
            phone: values?.phone,
            address: values?.address,
            postal_code: values?.postal_code,
            city_id: values?.city_id,
            state_id: values?.state_id,
            country_id: 101,
            user_id: userData?.id,
            customer_name: values?.customer_name,
            address_type: values?.address_type,
        }
        dispatch(AddUserAddress(payload));
        resetForm();
    }

    const shippingValidation = () => Yup.object({
        phone: phone_number,
        address: validAddress,
        state_id: state_id,
        city_id: city_id,
        postal_code: postal_code,
        address_type: Yup.string().required('Please select an address type'),
        customer_name: Yup.string().required('Customer name is required'),
    });

    const shippingFormik = useFormik({
        initialValues: {
            phone_code: '',
            phone: '',
            address: '',
            city_id: '',
            postal_code: '',
            state_id: '',
            user_name: '',
            address_type: '',
            customer_name: '',
            address_type: '',
        },
        validationSchema: shippingValidation,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        dispatch(fetchCountries());
        dispatch(fetchStates(101));
    }, []);

    useEffect(() => {
        if (shippingFormik.values.state_id) {
            dispatch(fetchCities(shippingFormik.values.state_id));
        }
    }, [dispatch, shippingFormik.values.state_id]);

    const handlePhoneChange = (value, country) => {
        const dialCodeLength = country.dialCode.length;
        const phoneWithoutCode = value.slice(dialCodeLength);

        shippingFormik.setFieldValue('phone_code', `+${country.dialCode}`);
        shippingFormik.setFieldValue('phone', phoneWithoutCode);
    };

    const filterCountry = countries?.find(co => co.id === 101)?.name;

    return (
        <Modal size='lg' show={show} onHide={onHide}>
            <Modal.Header className='mb-4' closeButton>
                <Modal.Title>Add New Delivery Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='address-form'>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Name</label>
                                <input type="text" className="form-control" value={shippingFormik.values.customer_name}
                                    onChange={shippingFormik.handleChange} name='customer_name'
                                    onBlur={shippingFormik.handleBlur} />
                                {shippingFormik.touched.customer_name && shippingFormik.errors.customer_name ? (
                                    <div className="error">{shippingFormik.errors.customer_name}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Mobile No.</label>
                                <PhoneInput inputClass='w-100 phone-flag-input'
                                    country={'in'}
                                    inputExtraProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true,
                                    }}
                                    name="phone"
                                    placeholder="Phone no *"
                                    onChange={handlePhoneChange}
                                    onBlur={shippingFormik.handleBlur}
                                    value={`${shippingFormik.values.phone_code}${shippingFormik.values.phone}`}
                                />
                                {shippingFormik.touched.phone && shippingFormik.errors.phone ? (
                                    <div className="error">{shippingFormik.errors.phone}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Country</label>
                                <input type="text" className="form-control" value={filterCountry} disabled />
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">State</label>
                                <select className="form-control form-select" value={shippingFormik.values.state_id}
                                    onChange={shippingFormik.handleChange} name='state_id'
                                    onBlur={shippingFormik.handleBlur}>
                                    <option value=''>Select State</option>
                                    {states?.map((e, i) =>
                                        <option value={e?.id} key={i}>{e?.name}</option>
                                    )}
                                </select>
                                {shippingFormik.touched.state_id && shippingFormik.errors.state_id ? (
                                    <div className="error">{shippingFormik.errors.state_id}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">City</label>
                                <select className="form-control form-select" value={shippingFormik.values.city_id}
                                    onChange={shippingFormik.handleChange} name='city_id'
                                    onBlur={shippingFormik.handleBlur}>
                                    <option value=''>Select City</option>
                                    {cities?.map((e, i) =>
                                        <option value={e?.id} key={i}>{e?.name}</option>
                                    )}
                                </select>
                                {shippingFormik.touched.city_id && shippingFormik.errors.city_id ? (
                                    <div className="error">{shippingFormik.errors.city_id}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Pincode</label>
                                <input type='number' className='form-control' value={shippingFormik.values.postal_code}
                                    onBlur={shippingFormik.handleBlur}
                                    name='postal_code' onChange={shippingFormik.handleChange} />
                                {shippingFormik.touched.postal_code && shippingFormik.errors.postal_code ? (
                                    <div className="error">{shippingFormik.errors.postal_code}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className='col-12'>
                            <div className='mb-3'>
                                <label htmlFor="name" className="form-label">Address</label>
                                <textarea className='form-control'
                                    onBlur={shippingFormik?.handleBlur} name='address'
                                    onChange={shippingFormik.handleChange}
                                    value={shippingFormik.values.address} />
                                {shippingFormik.touched.address && shippingFormik.errors.address ? (
                                    <div className="error">{shippingFormik.errors.address}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="col-12 mb-3">
                            <label className="form-label">Address Type</label>
                            <div className="d-flex">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" id="home" name="address_type" value="Home"
                                        onChange={shippingFormik.handleChange}
                                        onBlur={shippingFormik.handleBlur}
                                        checked={shippingFormik.values.address_type === "Home"} />
                                    <label className="form-check-label" htmlFor="home">
                                        Home
                                    </label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" id="work" name="address_type" value="Work"
                                        onChange={shippingFormik.handleChange}
                                        onBlur={shippingFormik.handleBlur}
                                        checked={shippingFormik.values.address_type === "Work"} />
                                    <label className="form-check-label" htmlFor="work">
                                        Work
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" id="other" name="address_type" value="Other"
                                        onChange={shippingFormik.handleChange}
                                        onBlur={shippingFormik.handleBlur}
                                        checked={shippingFormik.values.address_type === "Other"} />
                                    <label className="form-check-label" htmlFor="other">
                                        Other
                                    </label>
                                </div>
                            </div>
                            {shippingFormik.touched.address_type && shippingFormik.errors.address_type ? (
                                <div className="error">{shippingFormik.errors.address_type}</div>
                            ) : null}
                        </div>

                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <div className="form-buttons d-flex justify-content-end">
                    <button type="button" className="checkout-proceed-btn w-100" onClick={shippingFormik.handleSubmit} disabled={addAddressLoading}>
                        {addAddressLoading ? 'Processing...' : 'Save'}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default AddressForm