import { createSlice } from "@reduxjs/toolkit";
import { ESTIMATED } from '../helpers/slice.name';

const estimatedorderSlice = createSlice({
    name: ESTIMATED,
    initialState: {

        estimatedLoading: false,
        estimatedOrder: {},
        estimatedMessage: '',
        estimatedError: '',
    },
    reducers: {
        estimatedRequest: (state, action) => {
            state.estimatedLoading = true
            state.estimatedMessage = ''
            state.estimatedError = ''
        },
        estimatedSuccess: (state, action) => {
            state.estimatedLoading = false
            state.estimatedOrder = action.payload.data
            state.estimatedMessage = action.payload.message
        },
        estimatedError: (state, action) => {
            state.estimatedLoading = false
            state.estimatedError = action.payload.error

        },
        estimatedReset: (state, action) => {
            state.estimatedMessage = ''
            state.estimatedError = ''
        },
    }
})

export const {
    estimatedRequest,
    estimatedSuccess,
    estimatedError,
    estimatedReset,

} = estimatedorderSlice.actions
export default estimatedorderSlice.reducer;