import { postFormData, post } from ".";

const URI = "/return-request";

const bankDetails = (payload) => {
    const URL = `${URI}/add-bank-details`;
    return postFormData(URL, payload);
};

const bankaccountService = {
    bankDetails,
}
export default bankaccountService;

