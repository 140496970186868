import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'

const UploadImageModal = ({ show, handleClose, handleFileChange }) => {
    const fileInputRef = useRef(null);
    const handleDivClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChangeAndClose = (event) => {
        handleFileChange(event);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} centered className="modal fade upload-video-images">
            <Modal.Header closeButton>
                <Modal.Title>Upload Photos & Videos</Modal.Title>
            </Modal.Header>
            <Modal.Body className="upload-video-images-body">
                <div className="modal-content-inner" style={{ cursor: 'pointer' }} onClick={handleDivClick}>
                    <input
                        type="file"
                        className="d-none"
                        ref={fileInputRef}
                        accept=".png,.jpg,.mp4"
                        multiple
                        onChange={handleFileChangeAndClose}
                    />
                    <div className="mb-2 modal-img-inner">
                        <img src="assets/images/Picture.png" alt="Upload illustration" />
                    </div>
                    <p className='text-center'>Drop your image & video here, or <strong>browse</strong></p>
                    <p className='text-center'><span>Supports: PNG, JPG & MP4</span></p>
                </div>
                <div className="row justify-content-center mt-lg-3 mt-md-2 mt-4">
                    <div className="col-lg-3 col-md-4 col-sm-12 mt-3">
                        <button className="btn p-0 btn-orange mb-0 w-100" type='button' onClick={handleClose}>
                            <span>Upload</span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadImageModal