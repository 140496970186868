import { ReturnService } from "../../services/returnRequest";
import { getReturnRequestData, getReturnRequestError, getReturnRequestSuccess, returnRequestData, returnRequestError, returnRequestSuccess } from "../../slices/returnRequest";

export const returnRequestPayload = (payload) => {
    return (dispatch) => {
        dispatch(returnRequestData());
        ReturnService.returnRequest(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(returnRequestSuccess({ data: data?.data, message }));
                } else {
                    dispatch(returnRequestError(error));
                }
            }).catch((error) => {
                dispatch(returnRequestError(error));
            });
    }
}

export const getreturnRequestDeta = (payload) => {
    return (dispatch) => {
        dispatch(getReturnRequestData());
        ReturnService.getreturnRequest(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getReturnRequestSuccess({ data: data, message }));
                } else {
                    dispatch(getReturnRequestError(error));
                }
            }).catch((error) => {
                dispatch(getReturnRequestError(error));
            });
    }
}

// return track
export const trackReturnOrder = (payload) => {
    return (dispatch) => {
        dispatch(getReturnRequestData());
        ReturnService.getreturnOrderRequest(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getReturnRequestSuccess({ data: data, message }));
                } else {
                    dispatch(getReturnRequestError(error));
                }
            }).catch((error) => {
                dispatch(getReturnRequestError(error));
            });
    }
}