import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CancleConfOrder = ({ show, onHide }) => {
    const navigate = useNavigate()
    return (
        <Modal
            show={show}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md"
            keyboard={false}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                {/* <Modal.Title>Items Details</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className='success-content px-3'>
                    <div className='success-conten-img'>
                        <img src="/assets/images/svg-img/face-icon.svg" alt="check-img" />
                    </div>
                    <h4 className='mb-2 text-center px-5'>Your product is cancelled</h4>
                    <div className='row justify-content-center'>
                        <div className="col-6 success-conten-button d-flex justify-content-between">
                            <button className='w-100 btn btn-outline' onClick={() => { navigate('/') }}>Shop other product</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CancleConfOrder