import { createSlice } from "@reduxjs/toolkit";

const addressSlice = createSlice({
    name: 'address',
    initialState: {
        countries: [],
        states: [],
        cities: [],
        primary: false,
        deleteAdd: false,
        SuccessMessage: "",
        ErrorMessage: "",

        getAddressLoading: false,
        userAddress: [],
        getAddressMessage: "",
        getAddressError: "",

        addAddressLoading: false,
        addAddress: '',
        addAddressMessage: '',
        addAddressError: '',

        primaryAddressLoading: false,
        primaryAddressMessage: '',
        primaryAddressError: '',

        updateAddressLoading: false,
        updateAddressMessage: '',
        updateAddressError: '',
        updateAddress: '',

        deleteAddress: false,
        deleteAddressMessage: '',
        deleteAddressError: '',

        changeAddressLoading: false,
        changeAddress: [],
        changeAddressMessage: "",
        changeAddressError: "",
    },
    reducers: {
        getAllCountries: (state, action) => {
            state.countries = action.payload;
        },
        getAllStates: (state, action) => {
            state.states = action.payload;
        },
        getAllCities: (state, action) => {
            state.cities = action.payload;
        },

        getAddressRequest: (state) => {
            state.getAddressLoading = true
            state.getAddressMessage = ""
            state.getAddressError = ""
        },
        getAddressSuccess: (state, action) => {
            state.getAddressLoading = false
            state.userAddress = action.payload.data
            state.getAddressMessage = action.payload.message
        },
        getAddressFailure: (state, action) => {
            state.getAddressLoading = false
            state.getAddressError = action.payload
        },

        addAddressRequest: (state) => {
            state.addAddressLoading = true
            state.addAddressMessage = ""
            state.addAddressMessage = ""
        },
        addAddressSuccess: (state, action) => {
            state.addAddressLoading = false
            state.addAddress = action.payload.data
            state.addAddressMessage = action.payload.message

        },
        addAddressFailure: (state, action) => {
            state.addAddressLoading = false
            state.addAddressError = action.payload
        },
        addAddressReset: (state, action) => {
            state.addAddressMessage = "";
            state.addAddressError = "";
        },
        resetAddaddress: (state) => {
            state.addAddressMessage = ''
            state.addAddressError = ''
        },

        updateAddressRequest: (state) => {
            state.updateAddressLoading = true
            state.updateAddressMessage = ""
            state.updateAddressError = ""
        },
        updateAddressSuccess: (state, action) => {
            state.updateAddressLoading = false
            state.updateAddress = action.payload.data
            state.updateAddressMessage = action.payload.message
        },
        updateAddressFailure: (state, action) => {
            state.updateAddressLoading = false
            state.updateAddressError = action.payload
        },
        resetupdateaddress: (state) => {
            state.updateAddressMessage = ''
            state.updateAddressError = ''
        },

        setPrimary: (state) => {
            state.primary = false
        },
        primaryAddressRequest: (state) => {
            state.primaryAddressLoading = true
            state.primaryAddressMessage = ""
            state.primaryAddressError = ""
        },
        primaryAddressSuccess: (state, action) => {
            state.updateAddressLoading = false
            state.primary = action.payload.data
            state.primaryAddressMessage = action.payload.message
        },
        primaryAddressFailure: (state, action) => {
            state.updateAddressLoading = false
            state.primaryAddressError = action.payload
        },
        primaryAddressReset: (state) => {
            state.primaryAddressError = ""
            state.primaryAddressMessage = ""
        },

        deleteAddressRequest: (state) => {
            state.deleteAddressMessage = ""
            state.deleteAddressError = ""
        },
        setDeleteAddress: (state) => {
            state.deleteAdd = false
        },
        deleteAddressSuccess: (state, action) => {
            state.deleteAddress = action.payload
            state.deleteAdd = action.payload.data
            state.deleteAddressMessage = action.payload.message
        },
        deleteAddressFailure: (state, action) => {
            state.deleteAddressError = action.payload
        },
        deleteAddressReset: (state) => {
            state.deleteAddressMessage = ""
            state.deleteAddressError = ""
        },

        changeAddressRequest: (state, action) => {
            state.changeAddressLoading = true;
            state.changeAddressMessage = "";
            state.changeAddressError = "";
        },
        changeAddressSuccess: (state, action) => {
            state.changeAddressLoading = false;
            state.changeAddress = action.payload.data;
            state.changeAddressMessage = action.payload.message;
        },
        changeAddressError: (state, action) => {
            state.changeAddressLoading = false;
            state.changeAddressError = action.payload;
        },
        addAddressReset: (state, action) => {
            state.changeAddressError = "";
            state.changeAddressMessage = "";
        },

        changeAddressReset: (state, action) => {
            state.changeAddressMessage = "";
            state.changeAddressError = "";
        },

    }
});

export const {

    getAllCountries,
    getAllStates,
    getAllCities,

    getAddressRequest,
    getAddressSuccess,
    getAddressFailure,

    primaryAddressRequest,
    setPrimary,
    primaryAddressSuccess,
    primaryAddressFailure,
    primaryAddressReset,

    addAddressRequest,
    addAddressFailure,
    addAddressSuccess,
    addAddressReset,
    resetAddaddress,

    updateAddressFailure,
    updateAddressSuccess,
    updateAddressRequest,
    resetupdateaddress,

    deleteAddressRequest,
    setDeleteAddress,
    deleteAddressSuccess,
    deleteAddressFailure,
    deleteAddressReset,

    changeAddressRequest,
    changeAddressSuccess,
    changeAddressError,
    changeAddressReset


} = addressSlice.actions;
export default addressSlice.reducer;
