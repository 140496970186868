import { LOCALSTORAGE_CATEGORIES_KEY } from "../config/constants";
import LocalstorageService from "../helpers/localstorage-services";
import CategoryService from "../services/categories";
import {
  homeCategoryRequest,
  homeCategorySuccess,
  homeCategoryError,
  subCategoryRequest,
  subCategorySuccess,
  subCategoryError,
  allCategoryError,
  allCategorySuccess,
  allCategoryRequest,
} from "../slices/categories.slice";

export const fetchHomeCategories = (payload) => {
  return (dispatch) => {
    dispatch(homeCategoryRequest());
    CategoryService.getHomeCategories(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          // localStorage.setItem("categories",JSON.stringify(data))
          LocalstorageService.setInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY, data)
          dispatch(homeCategorySuccess({ data, message }));
        } else {
          dispatch(homeCategoryError(error));
        }
      })
      .catch((error) => {
        dispatch(homeCategoryError(error));
      });
  };
};

//get sub manues 
export const fetchsubCategories = (payload) => {
  return (dispatch) => {
    dispatch(subCategoryRequest());
    CategoryService.getSubCategories(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          // localStorage.setItem("categories",JSON.stringify(data))
          // LocalstorageService.setInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY, data)
          dispatch(subCategorySuccess({ data, message }));
        } else {
          dispatch(subCategoryError(error));
        }
      })
      .catch((error) => {
        dispatch(subCategoryError(error));
      });
  };
};

export const AllCategories = () => {
  return (dispatch) => {
    dispatch(allCategoryRequest());
    CategoryService.allSubCategories()
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          // localStorage.setItem("categories",JSON.stringify(data))
          // LocalstorageService.setInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY, data)
          dispatch(allCategorySuccess({ data, message }));
        } else {
          dispatch(allCategoryError(error));
        }
      })
      .catch((error) => {
        dispatch(allCategoryError(error));
      });
  };
};
