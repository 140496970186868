import { get, post, postFormData } from ".";

const URI = "/doodle-list";

const getDoodle = (payload) => {
    const URL = `${URI}`;
    return postFormData(URL, payload);
};

const DoodleService = {
    getDoodle,
}
export default DoodleService;

