import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { cancelSingleOrder } from '../../../middlewares/cart/order';
import { useDispatch } from 'react-redux';

const CancelOrder = ({ data, show, onHide }) => {
    const dispatch = useDispatch();

    const cancelOrder = () => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", data?.order?.id);
        dispatch(cancelSingleOrder(bodyFormData))
        onHide()
    }
    return (
        <Modal
            show={show}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="md"
            keyboard={false}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                {/* <Modal.Title>Items Details</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div className='success-content px-3'>
                    <div className='success-conten-img'>
                        <img src="/assets/images/svg-img/face-icon.svg" alt="check-img" />
                    </div>
                    <h4 className='mb-2 text-center px-5'>Do you really want to cancel
                        the product?</h4>
                    <p className='text-center mb-3'>Order ID: {data?.order?.code}</p>
                    <div className='row justify-content-center'>
                        <div className="col-4 success-conten-button d-flex justify-content-between">
                            <button className='w-100 btn' onClick={onHide}>NO</button>
                        </div>
                        <div className="col-4 success-conten-button d-flex justify-content-between">
                            <button className='w-100 btn btn-outline' onClick={cancelOrder}>Yes</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CancelOrder