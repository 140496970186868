import React, { useEffect, useRef, useState } from 'react'
import SideBarManu from '../SideBarManu'
import Footer from '../../../Components/Common/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import returnRequest, { returnRequestReset } from '../../../slices/returnRequest';
import { useDispatch, useSelector } from 'react-redux';
import { returnRequestPayload } from '../../../middlewares/Order/returnRequest';
import UploadImageModal from '../../../Components/Model/OrderTrack/UploadImageModal';
import { Accordion, Form } from 'react-bootstrap';
import { downloadInvo, getOrderTracking } from '../../../middlewares/cart/order';
import { toast } from 'react-toastify';
import { invoiceReset } from '../../../slices/orderSlice';
import { isWidgetOpened, toggleWidget } from 'react-chat-widget';
import ChatWidget from '../../ChatWidget';

const ReturnPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isReturnActive, setIsReturnActive] = useState(false);
    const [isExchangeActive, setIsExchangeActive] = useState(false);
    const [isQueryActive, setIsQueryActive] = useState(false);
    const [selectedReason, setSelectedReason] = useState('');
    const handleModalClose = () => setShowUploadModal(false);
    const handleModalShow = () => setShowUploadModal(true);
    const [isChatOpen, setIsChatOpen] = useState(false);

    const { invoiceDownload, invoiceMessage, invoiceLoading, getOrderTrack } = useSelector((state) => state?.order);
    const { order, status, track, estimated_delivery } = getOrderTrack;
    const { returnRequestLoading, returnRequestMessage, returnRequestError, returnRequest } = useSelector((state) => state?.returnRequest);
    const productData = getOrderTrack?.order?.orderDetails?.find((o) => o.product.id === location?.state?.proId)?.product;
    const { accountLoading, accountOrder, accountMessage, accountError } = useSelector((state) => state?.accountDetails);

    const toggleChatWidget = () => {
        setIsChatOpen(!isChatOpen);
        handleOpenChatWidget();
      };
    
      // Function to open the chat widget and load chat history if not already opened
      const handleOpenChatWidget = () => {
        if (!isWidgetOpened()) {
          toggleWidget();
        }
      };
    
    
    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", location.state.oId);
        dispatch(getOrderTracking(bodyFormData))
    }, []);

    useEffect(() => {
        if (returnRequestMessage) {
            toast.success(returnRequestMessage);
            navigate('/return-orders');
            dispatch(returnRequestReset());
        }
        if (returnRequestError) {
            toast.error(returnRequestError[0]);
        }
    }, [returnRequestMessage, returnRequestError]);

    const formik = useFormik({
        initialValues: {
            returnItem: '',
            reason: '',
            Is_other_reason: '',
            proof: [],
        },
        onSubmit: (values) => {
            const bodyFormData = new FormData();
            bodyFormData.append("order_id", location.state.oId);
            bodyFormData.append("order_detail_id", getOrderTrack?.order?.order_details?.find((od) => od?.product_id === location?.state?.proId)?.id);
            bodyFormData.append("reason", values.reason);
            if (values.reason === 'Other') {
                bodyFormData.append("Is_other_reason", values.Is_other_reason);
            }

            values.proof.forEach((file, index) => {
                bodyFormData.append(`proof[${index}]`, file);
            });
            dispatch(returnRequestPayload(bodyFormData));
        },
    });

    // const handleReturnChange = (event) => {
    //     const value = event.target.value;
    //     if (value === '1') {
    //         setSelectedOption('1');
    //         formik.setFieldValue('reason', 'Other');
    //     } else {
    //         setSelectedOption(value);
    //         formik.setFieldValue('reason', value);
    //         formik.setFieldValue('Is_other_reason', '');
    //     }
    // };

    const handleFileChange = (event) => {
        formik.setFieldValue('proof', [...formik.values.proof, ...event.target.files]);
    };

    const removeImage = (index) => {
        const updatedFiles = formik.values.proof.filter((_, i) => i !== index);
        formik.setFieldValue('proof', updatedFiles);
    };
    useEffect(() => {
        if (invoiceMessage) {
            toast.success(invoiceMessage);
            window.open(invoiceDownload, '_blank');
            dispatch(invoiceReset());
        }
    }, [invoiceMessage, invoiceDownload, dispatch]);

    const downloadInvoice = () => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", location.state.oId);
        dispatch(downloadInvo(bodyFormData))
    }
    const handleReturnChange = (event) => {
        setSelectedOption('return');
        formik.setFieldValue('reason', '');
        formik.setFieldValue('Is_other_reason', '');
        setSelectedReason('');

        setIsReturnActive(true);
        setIsExchangeActive(false);
        setIsQueryActive(false);
    };

    const handleExchangeChange = (event) => {
        setSelectedOption('exchange');
        formik.setFieldValue('reason', '');
        formik.setFieldValue('Is_other_reason', '');
        setSelectedReason('');

        setIsReturnActive(false);
        setIsExchangeActive(true);
        setIsQueryActive(false);
    };

    const handleQueryChange = (event) => {
        setSelectedOption('query');
        formik.setFieldValue('reason', '');
        formik.setFieldValue('Is_other_reason', '');
        setSelectedReason('');

        setIsReturnActive(false);
        setIsExchangeActive(false);
        setIsQueryActive(true);
    };

    const handleReasonChange = (event) => {
        const reason = event.target.value;
        setSelectedReason(reason);
        formik.setFieldValue('reason', reason);

        if (reason === '1') {
            formik.setFieldValue('Is_other_reason', '');
        }
    };
    const isSubmitEnabled = isReturnActive && formik.values.proof.length > 0

    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Not happy with product?</h3>
                    </div>
                </div>
            </section>

            <section className='order-tracking'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <div className="order-wpr-left ">
                                <div className="order-product">
                                    <img src={productData?.photos[0]?.path} alt="order-img" />
                                </div>
                                <div className="order-content">
                                    <h6>{productData?.brand_name}</h6>
                                    <p>{productData?.name}</p>
                                    <h5><span>Rs. {productData?.indiazona_price}</span></h5>
                                    <div className='w-100 mt-4'>
                                        <button className="btn style-1 btn-primary-filled-slide  p-0" type='button' onClick={() => navigate(`/${productData?.category_slug}/product-details/${productData?.id}`)}>
                                            <span>View Product</span>
                                        </button>
                                        {/* <a className="btn style-2 btn-outline-primary-slide p-0">
                                            <span>Reorder Product</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12">
                            <div className="order-wpr-right">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                        <li className="breadcrumb-item"><a href="#">Orders</a></li>
                                        <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                        <li className="breadcrumb-item" aria-current="page"><a href="#">ID 3352655445</a></li>
                                        <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                        <li className="breadcrumb-item" aria-current="page"><a href="#">Product return</a></li>
                                    </ol>
                                </nav>

                                <div className='row justify-content-between align-items-center mt-2'>
                                    <div className=' col-xl-6 col-lg-5 col-md-12 col-sm-12'>
                                        <div className='order-Id-Number'>
                                            <h4 className='mb-lg-0 mb-md-2 mb-2'>Order ID: {getOrderTrack?.order?.code}</h4>
                                        </div>
                                    </div>
                                    <div className=' col-xl-6 col-lg-7 col-md-12 col-sm-12'>
                                        <div className='order-Invoice-btn'>
                                            <div className="row justify-content-lg-end justify-content-md-end justify-content-sm-start">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                                    <button className='Invoice-btn w-100' onClick={() => downloadInvoice(order?.id)} disabled={invoiceLoading}><img src="/assets/images/svg-img/Invoice.svg" alt="Invoice" /> {invoiceLoading ? "Processing..." : "Invoice"}</button>
                                                </div>
                                                {/* <div className="col-xl-5 col-lg-6 col-md-6 col-6">
                                                    <button className='btn track-btn w-100' onClick={() => navigate('/return-details')}> Track order <img src="/assets/images/svg-img/location-order.svg" alt="Invoice" /> </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section className='Product-return-wpr'>
                                    <div className="row Product-return-check-input">
                                        <div className="col-12">
                                            <div className="accordion accordion-flush" id="accordionFlushExample">

                                                <div className="accordion-item mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="return" id="7days" value="return" onChange={handleReturnChange} checked={selectedOption === 'return'} />
                                                        <label className="form-check-label px-1" htmlFor="7days">
                                                            Do you want to return the product? (Applicable within 7 days)
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="exchange" id="Exchange" value="exchange" onChange={handleExchangeChange} checked={selectedOption === 'exchange'} />
                                                        <label className="form-check-label px-1" htmlFor="Exchange">
                                                            Do you want to exchange the product? (Applicable within 7 days)
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="accordion-item mb-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="query" id="query" value="query" onChange={handleQueryChange} checked={selectedOption === 'query'} onClick={toggleChatWidget}/>
                                                        <label className="form-check-label px-1" htmlFor="query">
                                                            Any other query chat with us?
                                                        </label>
                                                    </div>
                                                    {isChatOpen && <ChatWidget />}
                                                </div>
                                            </div>
                                        </div>

                                        {isReturnActive && (
                                            <div className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <h6 className='mb-2'>Select Reason why you want to return the product</h6>
                                                    <div className='px-3'>
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            id="DamagedProduct"
                                                            label="Damaged Product"
                                                            onChange={handleReasonChange}
                                                            value="Damaged Product"
                                                            checked={selectedReason === 'Damaged Product'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            id="WrongItemDelivered"
                                                            label="Wrong Item Delivered"
                                                            onChange={handleReasonChange}
                                                            value="Wrong Item Delivered"
                                                            checked={selectedReason === 'Wrong Item Delivered'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            className='mb-2'
                                                            id="ItemNotAsDescribed"
                                                            label="Item Not as Described"
                                                            onChange={handleReasonChange}
                                                            value="Item Not as Described"
                                                            checked={selectedReason === 'Item Not as Described'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            className='mb-2'
                                                            id="ReceivedDefectiveProduct"
                                                            label="Received Defective Product"
                                                            onChange={handleReasonChange}
                                                            value="Received Defective Product"
                                                            checked={selectedReason === 'Received Defective Product'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            className='mb-2'
                                                            id="SizeFitIssue"
                                                            label="Size/Fit Issue"
                                                            onChange={handleReasonChange}
                                                            value="Size/Fit Issue"
                                                            checked={selectedReason === 'Size/Fit Issue'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            className='mb-2'
                                                            id="BetterPriceAvailableElsewhere"
                                                            label="Better Price Available Elsewhere"
                                                            onChange={handleReasonChange}
                                                            value="Better Price Available Elsewhere"
                                                            checked={selectedReason === 'Better Price Available Elsewhere'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            id="ChangeofMind"
                                                            label="Change of Mind"
                                                            className='mb-2'
                                                            onChange={handleReasonChange}
                                                            value="Change of Mind"
                                                            checked={selectedReason === 'Change of Mind'}
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            name="reason"
                                                            id="Other"
                                                            label="Other (please specify)"
                                                            onChange={handleReasonChange}
                                                            value="1" // The value for "Other" option
                                                            checked={selectedReason === '1'}
                                                        />
                                                        {selectedReason === '1' && (
                                                            <Form.Control
                                                                as="textarea"
                                                                className='textArea'
                                                                rows={3}
                                                                name="Is_other_reason"
                                                                value={formik.values.Is_other_reason}
                                                                onChange={formik.handleChange}
                                                                placeholder="Please specify your reason"
                                                            />
                                                        )}

                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <button className='btn upload-btn' type='button' onClick={handleModalShow}>
                                                                    <span className='px-2'><img src="/assets/images/svg-img/upload-file.svg" alt="" /></span>
                                                                    Upload Proof Images & videos
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                                {formik.values.proof.length > 0 && (
                                                                    <div className="upload-image-box">
                                                                        <div className="row">
                                                                            {formik.values.proof.map((file, index) => {
                                                                                const fileUrl = URL.createObjectURL(file);
                                                                                return (
                                                                                    <div key={index} className="col-lg-2 col-md-2 col-3">
                                                                                        <div className="upload-photos">
                                                                                            <div className='upload-retune-product-img'>
                                                                                                {file.type.startsWith('image/') ? (
                                                                                                    <img
                                                                                                        src={fileUrl}
                                                                                                        alt={`img-${index}`}
                                                                                                        className="img-fluid"
                                                                                                    />
                                                                                                ) : (
                                                                                                    <video width="100%" height="auto" controls>
                                                                                                        <source src={fileUrl} type={file.type} />
                                                                                                    </video>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="close-icon-rerun-img">
                                                                                                <i className="fa-solid fa-x" onClick={() => removeImage(index)}></i>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="row justify-content-end">
                                                            <div className="col-lg-3 col-md-4 col-sm-12 mt-3">
                                                                <button type="submit" className="btn style-1 btn-primary-filled-slide p-0" onClick={formik.handleSubmit} disabled={!isSubmitEnabled}>
                                                                    {returnRequestLoading ? 'Processing...' : 'Submit'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* {isExchangeActive && (
                                            <div className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <h6 className='mb-2'>Select Reason why you want to return the product</h6>
                                                    <div className='px-3'>
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                Damaged Product
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                Wrong Item Delivered
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                Item Not as Described
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                                Received Defective Product
                                                            </label>
                                                        </div>
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                                Size/Fit Issue
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                                Better Price Available Elsewhere
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                                Change of Mind
                                                            </label>
                                                        </div>

                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" checked />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault8">
                                                                Other (please specify)
                                                            </label>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <button className='btn upload-btn'><span className='px-2'><img src="/assets/images/svg-img/upload-file.svg" alt="" /></span> Upload Proof Images & videos</button>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-end">
                                                            <div className="col-lg-3 col-md-4 col-sm-12 mt-3">
                                                                <button type="submit" className="btn style-1 btn-primary-filled-slide p-0" onClick={formik.handleSubmit} disabled={true}>
                                                                    {returnRequestLoading ? 'Processing...' : 'Submit'}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                        {/* {isQueryActive && (
                                            <div className="accordion-collapse">
                                                <div className="accordion-body">
                                                    <h6 className='mb-2'>Your query details</h6>
                                                    <div className='px-3'>
                                                        <p>Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )} */}
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <UploadImageModal
                show={showUploadModal}
                handleClose={handleModalClose}
                handleFileChange={handleFileChange}
            />
        </>
    )
}

export default ReturnPage