import React from 'react'
import { Link } from 'react-router-dom'
import './landingpage.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SELLER_ADD, SELLER_LOGIN } from '../../BaseUrl'
// import { BsFillTelephoneFill } from "react-icons/bs";
const Benifits = () => {
  return (
    <>
    <div className="w-100 m-0 pb-4 h-100" style={{backgroundColor:"#FFF8F0"}}>
      <header className="landing-header" >
        <div className="container-xxl">
          <div className="row align-items-center justify-center header-container" >
            <div className="col-lg-6 col-md-6 col-6">
              <div className="header-logo py-2">
                <div className="logo-inner">
                  <Link className="navbar-brand d-md-block d-lg-block " to="/home">
                    <img src="/assets/images/svg-img/logo.svg" alt="Logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="social-icon">
                <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center">
                  <p className='d-lg-block d-md-block d-none'>Follow @indiazonafamily</p>
                  <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
                  <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
                  <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
                  {/* <li><a href="#"><i className="fa-brands fa-youtube" style={{ color: "#ff131b", fontSize: '20px' }}></i> </a></li> */}
                  <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="container-xxl mt-5 ">
        <div className="row d-flex justify-content-between align-center mx-2 benifits-img">
            <div className="col-md-4 col-xs-12 w-50  text-center">
                <img src="/assets/images/landing-page/benifits-2.png" alt="" className='w-100'/>
            </div>
            <div className="col-md-4 col-xs-12 w-50 ">
                <img src="/assets/images/landing-page/benifits-1.png" alt=""  className='w-100 ms-2'/>
            </div>
        </div>
      </div>

      <div className="container-xxl mt-5">
        <div className="row">
            <div className="col-lg-12 text-center mt-3">
                <h5 className='bold'>Watch this video to understand in details:</h5>
            </div>
            <div className="col-lg-12 text-center benifits-video"><video controls>
                            <source src="https://portal.indiazona.in/public/assets/video/Indiazona-ppt.mp4" type="video/mp4"/>
                        </video></div>
            <div className="col-lg-12 text-center  mt-1 benifits-btn">
                <a class="btn mx-1 my-1 btn-hover btn-res" href={SELLER_LOGIN}  style={{color:"#FF8C00",border:"2px solid #FF8C00",borderRadius:"10px"}}>Login to Vendor</a>
              <a class="btn mx-1 my-1 btn-res" href={SELLER_ADD} role="button" style={{background:"#FF8C00",color:"white",borderRadius:"10px"}}>Become our Vendor</a>
            </div>
        </div>
      </div>

      </div>
 
    </>
  )
}

export default Benifits