import React from 'react'

const ProductHomeCategory = ({ proCat, }) => {
    return (
        <>
            {proCat?.map((elem, i) =>
                <section className="Product-home-category" key={i} >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center align-items-center">
                                <ul>
                                    <li>
                                        <h4 className="Title text-center">{elem?.title}</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-lg-3 mt-md-3 mt-4">
                            {elem?.product?.map((e, index) =>
                                <div className="col-lg-3 col-md-3 col-6 mt-3" key={index}>
                                    <div className="Product-home-category-img">
                                        <a href="">
                                            <img src={e.img} alt={`cat-pro-${index}`} />
                                            <h6>{e.name}</h6>
                                        </a>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default ProductHomeCategory