export const AUTH_SLICE = "auth";
export const USERS = "users";
export const PRODUCTS = "products";
export const COLOR = "colors";
export const CATEGORY = "categories";
export const CART = "carts";
export const BUY_NOW = "buyNow";
export const SHORTVIDEO = "shortvideo";
export const SIDEBARFILTER = "sidebarFilter";
export const WISHLIST = "wishLists";
export const DOODLE = "doodles";
export const SEARCH = "search";
export const SEARCH_ON_SUBMIT = "searchOnSubmit";
export const ORDER = "order";
export const ESTIMATED = "estimatedOrder";
export const ACCOUNTDETAILS = "accountDetails";
export const RETURNREQUEST = "returnRequest";
export const CHAT = "chat";