import React from 'react'
import { Link } from 'react-router-dom'

const REPolicy = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Return & Exchange Policy</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Return & Exchange Policy Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="section-b-space layout-light">
                <div className="custom-container container">
                    <div className="row gy-4">
                        <div className="col-12 mb-0">
                            <div className="about-title">
                                <h5 className='mb-2'>Return & Exchange Policy</h5>
                                <ul className='px-2'>
                                    <li className='mb-1' >
                                        <p > At IndiaZona, return and exchange options vary by product and are determined by individual vendors. Some products may not be eligible for returns, while others may have a limited time exchange policy, such as a 7-day window. Please review the product details before purchasing to understand the specific return and exchange terms.&nbsp;</p>
                                    </li>
                                    <li className='mb-1'>
                                        <p> If your product is categorized as &ldquo;No Return&rdquo;, still you have a window of 72 hours after successful delivery of product to raise any concern like: damaged/defective product, missing product, wrong product received, etc.&nbsp;</p>
                                    </li>
                                    <li className='mb-1'>
                                        <p>If your product arrives damaged or with missing parts, please make a detailed video and file a complaint. If your claim is valid, the vendor is required to return the product. Team IndiaZona reserves the right to make final decision in this regard.&nbsp;</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default REPolicy