import React from 'react';
import { Accordion } from 'react-bootstrap';
import parse from 'html-react-parser';

const ProductDetailsAccordion = ({ productDetails }) => {
    return (
        <div className="col-lg-7 col-md-7 col-sm-12">
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="border-0">
                        <h6 className="font-16">Description</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row mt-1">
                            {productDetails?.meta_description ? (
                                <p className="f-14 mb-1" > {parse(productDetails?.description)}</p>
                            ) : (
                                <p className="f-14 mb-1">Description not available.</p>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                    <Accordion.Header className='border-0'>
                        <h6 className="font-16">Specifications</h6>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="row mt-1">
                            <div className='col-12'>
                                {productDetails?.product_specification ? (
                                    <p dangerouslySetInnerHTML={{ __html: productDetails.product_specification }} />
                                ) : (
                                    <p className="f-14 mb-1">Specifications not available.</p>
                                )}
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default ProductDetailsAccordion;
