import { get, postFormData } from '.'

const URI = "/order";

const createOrder = (payload) => {
    return postFormData(`${URI}/create`, payload);
};
const createBuyNowOrder = (payload) => {
    return postFormData(`${URI}/create/buy-now`, payload);
};

const getOrderHistory = (payload) => {
    return postFormData(`/order-history`, payload);
}

const singleOrder = (payload) => {
    return postFormData(`/customer/order/list`, payload);
}

const cancelsingleOrder = (payload) => {
    return postFormData(`/customer/order/cancel`, payload);
}

const invoice = (payload) => {
    return postFormData(`${URI}/invoice/download`, payload);
}

const orderTracking = (payload) => {
    return postFormData(`/customer/order/track`, payload);
}

export const OrderService = {
    createOrder,
    createBuyNowOrder,
    getOrderHistory,
    singleOrder,
    cancelsingleOrder,
    invoice,
    orderTracking,
}
export default OrderService;
