import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SideBarManu from './SideBarManu';
import { useSelector, useDispatch } from 'react-redux';
import { AddUserAddress, DeleteUserAdd, fetchAddress, primaryAdd, UpdateAddress } from '../../middlewares/UserProfile/address';
import { useNavigate } from 'react-router-dom';
import AddressModal from './Address-Model/AddressModel';
import { EllipsisVertical } from 'lucide-react';
import { deleteAddressReset, primaryAddressReset, resetAddaddress, resetupdateaddress, setDeleteAddress, setPrimary } from '../../slices/addressSlice';
import ToastService from '../../helpers/toast-services';
import { Badge } from 'react-bootstrap';


const UserAddress = () => {
    const dispatch = useDispatch();
    const { userAddress, primary, deleteAdd, addAddressMessage, addAddressError,
        deleteAddressMessage, deleteAddressError,
        updateAddressMessage, updateAddressError,
        primaryAddressError, primaryAddressMessage, } = useSelector((state) => state.address);

    const navigate = useNavigate();
    const IsLogedin = localStorage.getItem('IN_USER_DATA');
    const userDetails = JSON.parse(IsLogedin);
    useEffect(() => {
        dispatch(fetchAddress())
    }, [dispatch, primary, deleteAdd]);

    useEffect(() => {
        if (addAddressMessage) {
            ToastService.success(addAddressMessage);
            dispatch(resetAddaddress())
        }
        if (addAddressError) {
            ToastService.error(addAddressError);
            dispatch(resetAddaddress())
        }
        if (updateAddressMessage) {
            ToastService.success(updateAddressMessage)
            dispatch(resetupdateaddress())
        }
        if (updateAddressError) {
            ToastService.error(updateAddressError)
            dispatch(resetupdateaddress())
        }
        if (deleteAddressMessage) {
            ToastService.success(deleteAddressMessage)
            dispatch(deleteAddressReset())
        }
        if (deleteAddressError) {
            ToastService.error(deleteAddressError)
            dispatch(deleteAddressReset())
        }
        if (primaryAddressMessage) {
            ToastService.success(primaryAddressMessage)
            dispatch(primaryAddressReset())
        }
        if (primaryAddressError) {
            ToastService.error(primaryAddressError)
            dispatch(primaryAddressReset())
        }
    }, [updateAddressMessage, updateAddressError, primaryAddressMessage, primaryAddressError, addAddressMessage, addAddressError, deleteAddressMessage, deleteAddressError]);

    useEffect(() => {
        !IsLogedin ? navigate('/') : <></>;
    }, [IsLogedin]);

    const [modalShow, setModalShow] = useState(false);
    const [AddressId, setAddressId] = useState();
    const [initialData, setInitialData] = useState();
    const [modalMode, setModalMode] = useState('add');

    const deleteAddress = (id) => {
        dispatch(setDeleteAddress())
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const payload = { 'id': id }
                dispatch(DeleteUserAdd(payload));
                Swal.fire(
                    'Deleted!',
                    'Your address has been deleted.',
                    'success'
                );
            }
        });
    };
    const setDefaulatAddress = (id) => {
        dispatch(setPrimary())
        Swal.fire({
            title: 'Are you sure?',
            text: "This address will be set as primary.",
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Yes, set as primary!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const payload = { "id": id };
                dispatch(primaryAdd(payload));
                Swal.fire(
                    'Success!',
                    'Address has been set as primary.',
                    'success'
                );
            }
        });
    };
    const handleModalOpen = (mode, id) => {
        setModalMode(mode);
        setAddressId(id);
        if (mode === 'edit') {
            const addressToEdit = userAddress.find(address => address.id === id);
            setInitialData(addressToEdit);
        } else {
            setInitialData(null);
        }
        setModalShow(true);
    };

    const handleSubmit = (values) => {
        if (modalMode === 'add') {
            dispatch(AddUserAddress(values));
        } else if (modalMode === 'edit') {
            dispatch(UpdateAddress({ ...values }));
        }
    };
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><a href="#">Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">User Address</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <div className="row">
                                    <div className="col-12 m-b30">
                                        <p className="m-b0">The following addresses will be used on the checkout page by default.</p>
                                    </div>
                                    {
                                        userAddress?.map((e, i) => (
                                            <div className={`col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 m-b30 ${e.set_default === 1 ? 'address-card primary' : 'address-card'}`} key={i}>
                                                <div className="account-address-box ">
                                                    <h6 className="mb-3">
                                                        Billing address
                                                        {e.set_default === 1 && <span className="mx-2 mb-0 badge add-primary">Primary</span>}
                                                    </h6>
                                                    <ul>
                                                        <li>{e?.customer_name}</li>
                                                        <li>{e.address},<span className="mx-1 m-0">{e.address_type}</span></li>
                                                        <li>{e.city}-{e.postal_code}</li>
                                                        <li>{e.state}</li>
                                                        <li>{e.country}</li>
                                                        <li>{e.phone_code} {e.phone}</li>
                                                        <li>{userDetails?.email}</li>
                                                    </ul>
                                                    <div className="account-address-bottom">
                                                        <div className="dropdown-center">
                                                            <EllipsisVertical data-bs-toggle="dropdown" aria-expanded="false" style={{ cursor: 'pointer' }} />
                                                            <ul className="dropdown-menu my-2">
                                                                <li><span className="d-block dropdown-item" onClick={() => handleModalOpen('edit', e.id)} style={{ cursor: 'pointer' }}>Edit <i className="fa-solid fa-pen px-1" /></span></li>
                                                                {/* {e.set_default !== 1 && ( */}
                                                                    <li><span className="d-block dropdown-item" onClick={() => { deleteAddress(e.id) }} style={{ cursor: 'pointer' }}>Delete <i className="fa-solid fa-trash-can px-1" /></span></li>
                                                                {/* )} */}
                                                                {e.set_default !== 1 && (
                                                                    <li><span className="d-block dropdown-item" onClick={() => { setDefaulatAddress(e.id) }} style={{ cursor: 'pointer' }}>Set As Primary</span></li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="col-12 mt-3">
                                        <div className="account-card-add">
                                            <div className="account-address-add">
                                                <svg id="Line" height={50} viewBox="0 0 64 64" width={50} xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m59.28775 26.0578-7.30176-6.251v-11.72068a.99973.99973 0 0 0 -1-1h-7.46a.99974.99974 0 0 0 -1 1v3.60693l-7.2109-6.17675a5.07688 5.07688 0 0 0 -6.6416 0l-23.97314 20.54345a2.04251 2.04251 0 0 0 1.32226 3.56787h5.98047v18.92188a8.60569 8.60569 0 0 0 8.59082 8.60059h10.481a1.00019 1.00019 0 0 0 -.00006-2h-10.48094a6.60308 6.60308 0 0 1 -6.59082-6.60059v-19.92188a1.00005 1.00005 0 0 0 -1-1l-6.99951-.05078 23.97119-20.542a3.08781 3.08781 0 0 1 4.03955 0l8.86133 7.59082a1.00655 1.00655 0 0 0 1.65039-.75934v-4.7802h5.46v11.18066a1.00013 1.00013 0 0 0 .34961.75928l7.63184 6.60156h-6.98148a.99974.99974 0 0 0 -1 1v3.7002a1.00019 1.00019 0 0 0 2-.00006v-2.70014h5.98145a2.03152 2.03152 0 0 0 1.32031-3.56982z" />
                                                    <path d="m43.99564 33.718a13.00122 13.00122 0 0 0 .00012 26.00244c17.24786-.71391 17.24231-25.29106-.00012-26.00244zm.00012 24.00244c-14.59461-.60394-14.58984-21.40082.00006-22.00244a11.00122 11.00122 0 0 1 -.00006 22.00244z" />
                                                    <path d="m49.001 45.71942h-4v-4.00049a1.00019 1.00019 0 0 0 -2 0v4.00049h-4a1.00019 1.00019 0 0 0 .00006 2h3.99994v4a1 1 0 0 0 2 0v-4h4a1 1 0 0 0 0-2z" />
                                                </svg>
                                            </div>
                                            <h4 className="mb-3">Add New Address</h4>
                                            <button className="btn desh-btn px-5 " onClick={() => handleModalOpen('add')}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AddressModal
                show={modalShow}
                mode={modalMode}
                addressId={AddressId}
                onHide={() => setModalShow(false)}
                initialData={initialData}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default UserAddress;
