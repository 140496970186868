import { get } from ".";

const getShortVideoService = (payload) => {
  const URL = `/short-video`;
  return get(URL, payload);
};

const ShortVideoService = {
  getShortVideoService,
};

export default ShortVideoService;
