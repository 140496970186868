import React from 'react';
import { useTranslation } from 'react-i18next';
import { SELLER_ADD, SELLER_LOGIN } from '../../BaseUrl';

const TopBar = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="top-bar-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 col-md-2">
            <div className="top_header_inner list-inline mb-0 h-100 d-flex justify-content-start align-items-center">
              {/* <div className="selectDiv d-flex gap-1">
                <div className="selectLanguage">
                  <select className="form-select form-select-sm" aria-label="Small select example" onChange={changeLanguage}>
                    <option value="en">English</option>
                    <option value="es">Español</option>
                    <option value="fr">Français</option>
                    <option value="hi">Hindi</option>
                  </select>
                </div>
                <div className="selectPayment">
                  <select className="form-select form-select-sm" aria-label="Small select example">
                    <option defaultValue>U.S. Dollar</option>
                    <option value="1">CAD Canadian</option>
                    <option value="2">Indian Rupee</option>
                    <option value="3">EUR-Euro</option>
                  </select>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 d-none d-lg-block d-md-block">
            <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center">
              <p>Follow @indiazonafamily</p>
              <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
              <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
              <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
              {/* <li><a href="#"><i className="fa-brands fa-youtube" style={{ color: "#ff131b", fontSize: '20px' }}></i> </a></li> */}
              <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-4 text-right  d-lg-block ">
            <ul className="loginSeller list-inline mb-0 h-100 d-flex  align-items-center">
              {/* <!-- Become a Seller --> */}
              <li className="top-btn">
                <a href={SELLER_ADD} target='_blank' rel="noopener noreferrer" className="tob-btn-a">
                  Become a Vendor !
                  {/* <p className="top-title"></p> */}
                  {/* <i className="fa-solid fa-handshake fa-beat" style={{ color: "#ffd563" }} />
                  <p className="top-description">Enjoy Benefits</p> */}
                </a>
              </li>
              <li className="top-btn">
                <a href={SELLER_LOGIN} target='_blank' rel="noopener noreferrer" className="tob-btn-a">
                  Login to Vendor
                  {/* <p className="top-title"></p> */}
                  {/* <i className="fa-solid fa-handshake fa-beat" style={{ color: "#ffd563" }} />
                  <p className="top-description top-description-2">Enjoy Benefits</p> */}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
