import React, { useEffect, useState } from 'react'
import SideBarManu from './SideBarManu'
import Footer from '../../Components/Common/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderData } from '../../middlewares/cart/order'
import { toast } from 'react-toastify'
import { getreturnRequestDeta } from '../../middlewares/Order/returnRequest'
import OrderPagination from '../../helpers/OrderPagination'
import Skeleton from 'react-loading-skeleton'

const OrdersReturnPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const IsLogedin = localStorage.getItem('IN_USER_DATA')
    const { getreturnRequestLoading, getreturnRequestData, getreturnRequestMessage, getreturnRequestError } = useSelector((state) => state?.returnRequest);

    useEffect(() => {
        dispatch(getreturnRequestDeta())
    }, []);

    useEffect(() => {
        if (IsLogedin) {
            navigate('/return-orders')
        } else {
            navigate('/')
            toast.error('Please log in again to continue');
        }
        IsLogedin ? navigate('/return-orders') : navigate('/')
    }, [IsLogedin]);

    const handlePagination = (e) => {
        const formData = new FormData();
        formData.append("page", e);
        dispatch(getreturnRequestDeta(formData));
    }
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Personal Information</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><a href="#">Home</a></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Orders Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container">
                    <div className="row">
                        <SideBarManu />
                        <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-8 account-wrapper">
                            <div className="account-card">
                                <div className="table-responsive table-style-1">
                                    {getreturnRequestLoading ? (
                                        <table className="table table-hover mb-3">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Order #</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Array.from({ length: 4 }).map((_, index) => (
                                                    <tr key={index}>
                                                        <td><Skeleton width={50} height={50} /></td>
                                                        <td><Skeleton width={100} /></td>
                                                        <td><Skeleton width={80} /></td>
                                                        <td><Skeleton width={80} /></td>
                                                        <td><Skeleton width={80} /></td>
                                                        <td><Skeleton width={50} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <table className="table table-hover mb-3">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Order #</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getreturnRequestData?.data?.map((elem, i) =>
                                                    <tr key={i}>
                                                        <td><img src={elem?.product_photos[0]} style={{ width: '50px', height: '50px' }} /></td>
                                                        <td><span className="fw-medium">{elem.order_code}</span></td>
                                                        <td>{elem.date}</td>
                                                        <td><span className="badge badgeReturn m-0">{elem.refund_label}</span></td>
                                                        <td><span className="badge bg-info m-0">{elem.product_price}</span></td>
                                                        <td className="btn-link text-underline p-0" onClick={() => navigate('/track-order-return', { state: { order_id: elem?.order_id, order_detail_id: elem?.order_detail_id } })} style={{ cursor: 'pointer' }}>track order</td>
                                                    </tr>
                                                )}
                                                {getreturnRequestData?.length === 0 && (
                                                    <tr>
                                                        <td colSpan="6" style={{ textAlign: 'center' }}>No Return Order History Found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                                <div className="row justify-content-center d-flex order-pagination">
                                    <div className="col-12">
                                        {getreturnRequestData?.metaData?.total >= 1 && (
                                            <OrderPagination
                                                prev={() => {
                                                    handlePagination(
                                                        parseInt(getreturnRequestData?.metaData?.current_page) - 1
                                                    );
                                                }}
                                                next={() => {
                                                    handlePagination(
                                                        parseInt(getreturnRequestData?.metaData?.current_page) + 1
                                                    );
                                                }}
                                                prevDisabled={
                                                    parseInt(getreturnRequestData?.metaData?.current_page) === 1
                                                }
                                                nextDisabled={
                                                    parseInt(getreturnRequestData?.metaData?.current_page) ===
                                                    getreturnRequestData?.metaData?.total
                                                }
                                                currentPage={parseInt(getreturnRequestData?.metaData?.current_page)}
                                                totalPages={getreturnRequestData?.metaData?.total}
                                                handlePageClick={(page) => handlePagination(page)}
                                            />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default OrdersReturnPage