import React from 'react'
import { Link } from 'react-router-dom'

const FAQ = () => {
    return (
        // <>
        //     <section className="page-title">
        //         <div className="pattern-layer"></div>
        //         <div className="container-full">
        //             <div className="content-box">
        //                 <h3>Faq Page</h3>
        //                 <nav aria-label="breadcrumb">
        //                     <ol className="breadcrumb mb-0">
        //                         <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
        //                         <li className="breadcrumb-item" aria-current="page">/</li>
        //                         <li className="breadcrumb-item" aria-current="page">Faqs</li>
        //                     </ol>
        //                 </nav>
        //             </div>
        //         </div>
        //     </section>

        //     <section className='faq-section'>
        //         <div className="container-fluid">
        //             <div className="row justify-content-center">
        //                 <div className="col-lg-8 col-md-10 col-sm-12">
        //                     <div className="faq-accordion">
        //                         <div class="accordion" id="accordionExample">
        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingOne">
        //                                     <button class="accordion-button faq-accordin-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        //                                         How did my package ship?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingTwo">
        //                                     <button class="accordion-button  faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        //                                         Why does my tracking number state 2-Day, 3-Day Delivery?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingThree">
        //                                     <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        //                                         How long will my package take to arrive?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingFour">
        //                                     <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
        //                                         What are business days?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingFive">
        //                                     <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
        //                                         How do I know my package has shipped?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>

        //                             <div class="accordion-item">
        //                                 <h6 class="accordion-header border-0 faq-accordion-header" id="headingSix">
        //                                     <button class="accordion-button faq-accordin-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        //                                         Why are certain products unavailable to ship to Internationally?
        //                                     </button>
        //                                 </h6>
        //                                 <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
        //                                     <div class="accordion-body">
        //                                         <p>Credibly engineer robust quality vectors before team building channels. Objectively do develop sticky vortals and tactical products. Proactively enhance its cooperative convergence with multimedia based leadership. Rapidiously brand client-centered action.Progressively expedite cutting-edge leadership whereas compelling human capital assertively parallel</p>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>

        //     <section className="need-section">
        //         <div className="container">
        //             <div className="need-wrapper">
        //                 <div className="row justify-content-center">
        //                     <div className="col-lg-6 col-md-6 col-sm-12">
        //                         <div className="need-inner text-center">
        //                             <h1>You Still Need Help?</h1>
        //                             <p className='mt-3 mb-4 '>Things are better as a Vendora Member. Get first and exclusive access to the newest style & innovation, free shipping, birthday rewards and more..</p>
        //                             <button className='btn btn-primary '><Link to='/contect-us'>Contact Us</Link></button>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </section>

        // </>
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Customer FAQs</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Customer FAQs Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="section-b-space layout-light">
                <div className="custom-container container">
                    <div className="row gy-4">
                        <div className="col-12 mb-0">
                            <div className="about-title">
                                <h1 style={{ "text-align": "justify" }}><span style={{ "font-size": "17pt" }}>CUSTOMER FAQs</span></h1>

                                <ul>
                                    <li style={{ "list-style-type": "disc", "font-size": "11pt" }}>

                                        <h3 style={{ "text-align": "justify" }}><span style={{ "color": "#0000ff" }}>Account Related&nbsp;</span></h3>
                                        <div className="customer-faq">
                                            <ul>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1'>1.</span> How do I register?</strong></p>
                                                </li>
                                                <p className='px-3 mb-2'>To register, go to the Indiazona website or app and follow the registration process by providing the required details.</p>
                                            </ul>
                                        </div>
                                        <div className="customer-faq bulat-point">
                                            <ul>
                                                <li className='mb-2'>
                                                    <p><strong> Customer Login Steps</strong></p>
                                                </li>
                                                <li>
                                                    <p><span className='px-2'> 1.</span>Go to the Indiazona website.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>2.</span>Click on &quot;Login&quot; option on top right corner.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>3.</span>Enter your full name and email address.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>4.</span>Create a strong password with at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>5.</span>Confirm your password</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>6.</span>Accept the terms and conditions.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2' >7.</span>Verify your email and mobile number via OTP verification.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>8.</span>Registration complete! You can now log in.</p>
                                                </li>
                                                <li >
                                                    <p><span className='px-2'>9.</span>Alternatively, register using your Google, Facebook, Apple ID, or Twitter account.</p>
                                                </li>
                                                <p><span className='px-2' ></span><strong className='px-2'>For more watch this video.</strong></p>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>2.</span> Are there any charges for registration?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>No, registration on Indiazona is free. You can simply create your account &amp; start shopping with us!&nbsp;</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>3.</span> What is my Account?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Your account is where you manage your personal information, orders, addresses, and payment methods. You can access your account from:</p>
                                                </li>

                                                <li>
                                                    <ul className='px-4'>
                                                        <li>
                                                            <p><span className='px-2'>Ⅰ.</span>Click on your profile icon or name at the top right corner.</p>
                                                        </li>
                                                        <li>
                                                            <p><span className='px-2'>ⅠⅠ.</span>Select &quot;My Account&quot; from the dropdown menu.</p>
                                                        </li>
                                                        <li>
                                                            <p><span className='px-2'>ⅠⅠⅠ.</span>View your account information, orders, and settings.</p>
                                                        </li>
                                                    </ul>
                                                </li>

                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>4.</span> Is it necessary to register on Indiazona for shopping or can I checkout as a guest?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You need to create an account to place orders and to track your orders conveniently from the app & also get access to exclusive discounts and offers. </p>
                                                </li>


                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>5.</span> Can I have multiple registrations from the same mobile and email?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3' >No, you can only have one registration per mobile number and email. </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>6.</span> How can I add more than one delivery address to my Indiazona account?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Yes, you can add multiple delivery addresses on Indiazona by visiting My Account <i className="fa-solid fa-arrow-right"></i> My Addresses <i className="fa-solid fa-arrow-right"></i> Add New Address <i className="fa-solid fa-arrow-right"></i> Save it!</p>
                                                    <p className='px-3'>Now whenever you checkout on Indiazona, you'll be able to choose from all the saved delivery addresses in your account for a faster and more convenient experience! </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>7.</span> How can I change my mobile number linked to my Indiazona account?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>To change your mobile number, go to the account settings.</p>
                                                </li>

                                                <li>
                                                    <p className='px-3'>My Account-&gt; Personal Details-&gt; Edit option -&gt; Change Mobile Number-&gt; Verify OTP -&gt; Click on update</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>8.</span> How do I reset my password?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>To reset your password, go to the login page and click on &quot;Forgot Password.&quot; Follow the instructions to reset it.</p>
                                                </li>

                                                <li>
                                                    <p className='px-3'>Click on Forgot Password-&gt; enter registered Email ID -&gt; OTP Verification-&gt; Change Password -&gt; Confirm Password&nbsp;</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li >
                                                    <p><strong > <span className='px-1'>9.</span> Does Indiazona read my SMSes?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Indiazona can only read OTP SMSes that are sent by Indiazona. This is in accordance with guidelines provided by Google and Apple in line with user privacy policies.</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong style={{ "font-size": "11pt" }}> <span className='px-1' >10.</span> Why is my account locked?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3' style={{ "font-size": "11pt" }}>Indiazona locks accounts for a specified time period or permanently if there are too many failed login attempts. This is to ensure the security of your account. You can regain access by resetting your password or contacting customer care for further assistance.</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >11.</span> How will I detect fraudulent emails/calls seeking sensitive personal and confidential information?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>If you receive an email or call from someone claiming to be from Indiazona requesting sensitive information like your debit/credit card PIN, net-banking, or mobile banking password, please do not provide such data. Indiazona and our logistics partners never ask for confidential personal information. If you have already shared such information, report it immediately to the appropriate law enforcement agency.</p>
                                                </li>
                                                <li>
                                                    <ul className='px-4'>
                                                        <li>
                                                            <p><strong>Here are a couple of tactics fraudsters often use to deceive consumers:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-2'><span ></span>Congratulations! You have been nominated as a &lsquo;Top Indiazona customer&rsquo; and are now eligible for a luxury gift item. Please share your proof of address and your debit/credit card details to avail of this great offer.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-2'><span ></span>Hi, I&rsquo;m calling from Indiazona. We are happy to let you know that you have won an exclusive lucky draw coupon of Rs. 5000 on your latest purchase. Please share your credit/debit card number so we can credit the money directly into your bank account.</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <h3 style={{ "text-align": "justify" }}><span style={{ "color": "#0000ff", "font-size": "13.999999999999998pt" }}>Payments</span></h3>
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >1.</span>What are the different payment methods that you can use?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You can pay easily through various payment options provided by us:</p>
                                                    <ul className='px-4 mt-2'>
                                                        <li>
                                                            <p className='px-2'><span className='px-1'>-</span>Cash on delivery</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-2'><span className='px-1'>-</span>UPI</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-2'><span className='px-1'>-</span>Net Banking</p>
                                                        </li>

                                                        <li>
                                                            <p className='px-2'><span className='px-1'>-</span>Debit Card & Credit Card</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-2'><span className='px-1'>-</span>Payment Wallets</p>
                                                        </li>

                                                    </ul>
                                                    <p className='px-3 mt-2'>However, at a time some might be disabled due to technical issues.</p>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >2.</span> How to change the payment method?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You can easily change  the payment method at the time of checkout.</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >3.</span>What is COD - cash on delivery?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Cash on delivery means that you can pay for the order when it gets delivered to you. You may not need to pay the full amount at the time of placing the order. </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >4.</span>Are there any charges on COD?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3' >Yes, Rs.50 per order until the order value is Rs.2500, above that as per the applicable rates.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >5.</span>What will happen if there is payment failure while placing an order.&nbsp;</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Incase payment fails & money has been deducted from your account. It will be refunded to you in 24 hours. You can reach out to us to incase of any further concerns.</p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >6.</span>Is it safe to use debit and credit cards on Indiazona?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Indiazona prioritizes your financial security. We use trusted & secure payment gateways that encrypt your sensitive information (like card details) during transactions making it safe to shop on Indiazona. </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >7.</span>What is COD limit?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Indiazona reserves the right to set the Cash on Delivery (COD) limit for each customer
                                                        and/or order. This limit includes any undelivered or unpaid orders as well as any new
                                                        orders being placed.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <h3 style={{ "text-align": "justify" }}><span style={{ "color": "#0000ff", "font-size": "13.999999999999998pt" }}>Returns &amp; Exchange&nbsp;</span></h3>
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >1.</span>How to create return request?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Go to 'My Orders,' select the order you wish to return, and click on 'Return Item.'</p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>State the reasons for the return and upload product images and product unboxing videos.</p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>If the product is not returnable, raise a request within 72 hours of delivery with proper proof.</p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Do not use the product and keep the box, tags, and seal intact.
                                                        Make sure to follow these steps to ensure a smooth return process.
                                                    </p>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">

                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >2.</span>What are the modes of refund available after cancellation?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Refunds can be processed through original payment methods like credit/debit cards, net banking,etc.
                                                    </p>
                                                </li>


                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">

                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >3.</span>Can I get a refund for the items purchased through pre approved EMI cards?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Yes, refunds for items purchased through pre-approved EMI cards will be processed to the same card.
                                                    </p>
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">

                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >4.</span>I have created a Return request. When will the product be picked up?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>The number of days to pick up a product may vary depending on the assigned logistics team. The product will be picked up within 7 day
                                                    </p>
                                                </li>


                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >5.</span>I have created a return request. When will I get my refund?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Refunds for return requests are typically processed within 10 business days after the product is received back.
                                                    </p>
                                                </li>


                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">

                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong > <span className='px-1' >6.</span>I have canceled my order. When will I get my refund?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Refunds for canceled orders are usually processed within 10 business days.
                                                    </p>
                                                </li>


                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <h3 style={{ "text-align": "justify" }}><span style={{ "color": "#0000ff", "font-size": "13.999999999999998pt" }}>Orders & Delivery</span></h3>
                                            <h4 style={{ "text-align": "justify" }}><u><span style={{ "color": "#38761d", "font-size": "12pt" }}>Placing an order&nbsp;</span></u></h4>
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >1.</span>How to change quantities of Orders in the cart?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Go to your cart, find the item you want to change, and use the '+' or '-' buttons to adjust the quantity.
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >2.</span>How to place bulk orders?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>To place bulk orders, simply add the desired quantities of items to your cart. Once you have all the items in your cart, proceed to checkout and complete your order. If you need further assistance, please contact our customer support team.

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >3.</span>Why am I unable to place an order?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Ensure you have proper internet connection, your payment method is valid, your address is correct, and the item is in stock. Contact support if the problem persists.
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >4.</span>Where can I find offers and promotions?

                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You can find offers and promotions on the homepage, in the "Offers" section, or through promotional emails. Specialized offers are sent over email, including deal of the day promotions. You can also find offers & coupon codes on the checkout page
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >5.</span>How can I order the same items again?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Go to 'My Orders,' find the item you wish to reorder, and click 'Buy Again.'

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >6.</span>Can I personalize my products? </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Yes, there are some products on Indiazona which can be personalized as per your requirements. Go to your desired section like the Made in India section or search your desired product  & then filter on “Personalized” and the relevant  products will show up.
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >7.</span>What is the Swipe & Buy Section?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>It is a unique feature of Indiazona using which you can shop through videos. Just swipe through videos until you find the product you love! Once you get it, simply click on “Buy Now” or “Add to Cart” making your shopping experience much more convenient.

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >8.</span>What is the Made in India Section?

                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>This section exclusively showcases the products which are Made in India, coming from the different parts of India.

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >9.</span>What is the Handmade Section?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>This section exclusively showcases handcrafted products made by artisans. It features
                                                        unique, high-quality items that celebrate traditional craftsmanship and support local
                                                        artisans.

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >10.</span>How to place Gift orders?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>During checkout, select the option to mark the order as a gift and provide the recipient's address.
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >11.</span>How much delivery charges are applicable?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Free Standard Delivery for all products!
                                                    </p>

                                                </li>

                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >12.</span>What is the minimum order value required for delivery?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>No minimum order value requirement on Indiazona, order anything you like.

                                                    </p>

                                                </li>

                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >13.</span>How can there be different prices for the same products?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Prices may vary due to different sellers, offers, or promotions.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >14.</span>How can I check if Indiazona delivers to my pincode?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Use “Check Availability Option” below the products to enter your pin code & know estimated time of delivery.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >15.</span>Does Indiazona have offline stores?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Indiazona is an online-only store. We offer a vast selection of products from across the country delivered straight to your door.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p><strong> <span className='px-1' >16.</span>Where do I enter the coupon code?
                                                    </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You can enter the coupon code at the checkout page before making the payment. You can paste the code in this section or you can select from the below available coupons.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <h4 style={{ "text-align": "justify" }}><span style={{ "color": "#38761d", "font-size": "12pt" }}>Edit Order&nbsp;</span></h4>
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>1.</span> Can I edit an address after placing an order?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You can easily edit your address, just go to My Account <i className="fa-solid fa-arrow-right"></i> My Addresses <i className="fa-solid fa-arrow-right"></i> Edit Address. Select the address, make the necessary changes & save it. However, the changes would reflect on your live order only before the order is dispatched. Please contact customer support if you want to edit the order post it is dispatched.

                                                    </p>

                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>2.</span> Can I change or modify orders that are already placed? </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>You cannot modify items of an order after it's placed, but you can cancel it if it's not yet shipped and place a new one.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>3.</span> I ordered the wrong item. How can I stop the order? </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Go to 'My Orders,' select the order, and click 'Cancel Order'. It will be canceled if it hasn't been shipped.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>4.</span> How to cancel orders? </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Go to 'My Orders,' select the order you wish to cancel, and click 'Cancel Order.' It will be canceled if it hasn't been shipped.
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <h4 style={{ "text-align": "justify" }}><span style={{ "color": "#38761d", "font-size": "12pt" }}>Tracking & Post Delivery &nbsp;</span></h4>
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>1.</span> How long will it take to get my order?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Delivery times vary based on your location and the shipping method selected. You can track your order status in 'My Orders.'

                                                    </p>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>2.</span> How can I get the invoice of my orders?  </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>An invoice is included with your shipment. You can also download it from 'My Orders.'
                                                    </p>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>3.</span> How can I give feedback on products?/How can I rate the products purchased by me?  </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-1'>You can give feedback and rate products through multiple methods:</p>

                                                    <ul className='mt-2'>
                                                        <li>
                                                            <p> <strong><span>I.</span> Order History Section: </strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Go to your account and select 'Order History.'</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Choose the order for which you want to give feedback.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Click on 'Rate Product' and provide your rating and feedback. </p>
                                                        </li>
                                                    </ul>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span>II.</span> Email</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Send your feedback and product ratings to our customer service email: care@indiazona.com</p>
                                                        </li>
                                                    </ul>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span>III.</span> Customer Service: </strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Contact our customer service team through the 'Contact Us' section on the website or app and share your feedback directly. </p>
                                                        </li>
                                                    </ul>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span>III.</span> App Notifications:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Respond to prompts or notifications in the app requesting feedback on your recent purchases.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- By using any of these methods, you help us improve our service and provide valuable insights for other customers.</p>
                                                        </li>
                                                    </ul>

                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>4.</span> How do I avail warranty for my product?  </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>- To avail warranty, follow these steps:'</p>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span>1.</span> Check Product Details:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Refer to the warranty process provided with your product packaging or documentation.</p>
                                                        </li>
                                                    </ul>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span>2.</span> Contact Customer Service:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- If you still face an issue, contact our customer service with your order details.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- They will guide you through the warranty claim process. </p>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <p>By following these steps, you can ensure a smooth process for availing the warranty on your product.</p>
                                            </ul>
                                        </div>


                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>5.</span> What do I do if an item is defective, broken, or expired?  </strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>If you receive a defective, broken, or expired item:</p>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span></span> Record Proof:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Record a proper unboxing video and take clear photos of the defective item</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Ensure the box, tags, and seals are intact.</p>
                                                        </li>
                                                    </ul>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p> <strong><span></span>Contact Customer Service:</strong></p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Share the video and photo proof with Indiazona customer service.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- They will assist you in initiating a return or replacement.</p>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>6.</span> Can I self-ship my products to Indiazona?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>Indiazona will send a return courier but if you self ship it won’t be reimbursed, or as the case may be. </p>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="customer-faq bulat-point">
                                            <ul className='mb-2'>
                                                <li className='mb-2'>
                                                    <p> <strong><span>7.</span> I have received a partial item/partial order or an Untenanted/Void packet?</strong></p>
                                                </li>
                                                <li>
                                                    <p className='px-3'>If you receive a partial item/order or an untenanted/void packet, please reach out to us within 72 hours of delivery, as claims will not be entertained after this period. During our investigation, please adhere to the following guidelines:
                                                    </p>

                                                    <ul className='mt-1'>
                                                        <li>
                                                            <p className='px-3'>- Do not use the item for which the claim is being raised</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Provide a short description of the issue (a few questions will be asked to understand the scenario).</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Submit snapshots of the packet and other boxes (if any), especially the sides that appear tampered/damaged.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Refunds for prepaid/Cash on Delivery orders will be processed after the investigation.</p>
                                                        </li>
                                                    </ul>


                                                    <ul className='mt-2'>
                                                        <p className='mt-1'><strong>Refund claims may be denied if:</strong></p>
                                                        <li>
                                                            <p className='px-3'>- Adequate information about the case is not provided. </p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Snapshots of the packet and box (if any) are not submitted.</p>
                                                        </li>
                                                        <li>
                                                            <p className='px-3'>- Pilferage claims are not made on the same day of delivery. </p>
                                                        </li>

                                                        <li>
                                                            <p className='px-3'>- Packaging is disposed of within 3-4 days post-delivery (we may need to pick up your packaging for investigation). </p>
                                                        </li>

                                                        <li>
                                                            <p className='px-3'>- The item has been used for which the claim was raised. </p>
                                                        </li>

                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>

                                    </li>
                                </ul>

                                <div className="customer-faq bulat-point">
                                    <h3 style={{ "text-align": "justify" }}><span style={{ "color": "#0000ff", "font-size": "13.999999999999998pt" }}>Customer Support </span></h3>
                                    <ul className='mb-2'>
                                        <li className='mb-2'>
                                            <p> <strong><span>1.</span> How do I contact customer service?</strong></p>
                                        </li>
                                        <li>
                                            <p className='px-3'>Get in touch with our customer service through the "Contact Us" on our website or app (available Monday-Sunday, 10am-6pm). You can also reach us anytime at care@indiazona.com.</p>
                                        </li>
                                    </ul>

                                    <ul className='mb-2'>
                                        <li className='mb-2'>
                                            <p> <strong><span>2.</span> What are the timings of customer service?</strong></p>
                                        </li>
                                        <li>
                                            <p className='px-3'>Customer service is available from Monday-Sunday between 10am to 6pm. </p>
                                        </li>
                                    </ul>

                                    <ul className='mb-2'>
                                        <li className='mb-2'>
                                            <p> <strong><span>3.</span> How can I give feedback on the quality of customer service?</strong></p>
                                        </li>
                                        <li>
                                            <p className='px-3'>You can provide feedback on the quality of customer service through various methods. Use the "Contact Us" section in the app or on the website to submit your feedback. Additionally, you may receive feedback forms after your interaction, which you can fill out. If you prefer, you can also email your feedback directly to <a style={{ "text-decoration": "underline", color: "blue" }}>care@indiazona.com.</a></p>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQ