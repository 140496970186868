import { get, post, postFormData } from ".";

const URI = "/return-request";

const returnRequest = (payload) => {
    const URL = `${URI}/send`;
    return postFormData(URL, payload);
}

const getreturnRequest = (payload) => {
    const URL = `${URI}/get-list`;
    return postFormData(URL, payload);
}

const getreturnOrderRequest = (payload) => {
    const URL = `customer/return/track`;
    return postFormData(URL, payload);
}

export const ReturnService = {
    returnRequest,
    getreturnRequest,
    getreturnOrderRequest
}