import React from 'react';

const DeliveryStatus = ({ type, status }) => {
    const defaultSteps = {
        Order: [
            { title: 'Order Confirmed', date: '', time: '', active: false },
            { title: 'Shipped', date: '', time: '', active: false },
            { title: 'Out For Delivery', date: '', time: '', active: false },
            { title: 'Delivered', date: '', time: '', active: false }
        ],
        CancleOrder: [
            { title: 'Order Confirmed', date: '', time: '', active: false },
            { title: 'Cancelled', date: '', time: '', active: false }
        ]
    };
    const defaultReturnSteps = [
        { title: 'Return Applied', date: '', time: '', active: false },
        { title: 'Return Approved', date: '', time: '', active: false },
        { title: 'Pick Up', date: '', time: '', active: false },
        { title: 'Refund', date: '', time: '', active: false }
    ];

    const OrderType = defaultSteps.Order
    const CancleOrderType = defaultSteps.CancleOrder

    const steps = type === 'return' ? defaultReturnSteps : type === 0 ? OrderType : CancleOrderType;
    const activeColor = type === 'return' || type === 1 ? '#ff8c00' : '#00c53c';

    const activeSteps = steps.map((step, index) => {
        const statusStep = status && status[index] ? status[index] : null;
        return {
            ...step,
            active: statusStep ? true : false,
            date: statusStep?.date || step.date,
            title: statusStep?.title || step.title
        };
    });

    return (
        <div className="delivery-progress">
            <div className="progress-container">
                {activeSteps.map((step, index) => (
                    <div className="progress-step" key={index}>
                        <p className="step-title">{step.title}</p>

                        <div
                            className={`circle ${step.active ? 'active' : ''}`}
                            style={{ backgroundColor: step.active ? activeColor : '#ccc' }}
                        />

                        {index < activeSteps.length - 1 && (
                            <div
                                className={`progress-line ${activeSteps[index + 1].active ? 'active' : ''} ${type === 1 ? 'cancleOrder' : ''}`}
                                style={{ backgroundColor: activeSteps[index + 1].active ? activeColor : '#ccc' }}
                            />
                        )}

                        <div className="step-info text-center">
                            {step.active && (
                                <>
                                    <small className="step-date">{step.date}</small>
                                    <br />
                                    <small className="step-time">{step.time}</small>
                                </>
                            )}
                            {step.expected && <small>Expected by, {step.expectedDate}</small>}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeliveryStatus;
