import { ProductService } from "../../services/product";
import {
  productListError,
  productListRequest,
  productListSuccess,

  productDetailsError,
  productDetailsRequest,
  productDetailsSuccess,
  todayDealRequest,
  todayDealSuccess,
  todayDealError,

} from "../../slices/products.slice";

export const fetchProducts = (payload) => {
  return (dispatch) => {
    dispatch(productListRequest());
    ProductService.productsDetails(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(productListSuccess({ data, message }));
        } else {
          dispatch(productListError({ data, message: error }));
        }
      })
      .catch((error) => {
        dispatch(productListError({ data: [], message: error }));
      });
  };
};

export const fetchProductDetails = (payload) => {
  return (dispatch) => {
    dispatch(productDetailsRequest());
    ProductService.productsDetailsById(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(productDetailsSuccess({ data: data, message }));
        } else {
          dispatch(productDetailsError({ data, message: error }));
        }
      }).catch((error) => {
        dispatch(productDetailsError(error));
      })
  }
}

export const fetchTodaysDeal = (payload) => {
  return (dispatch) => {
    dispatch(todayDealRequest())
    ProductService.getTodaysDeal(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(todayDealSuccess({ data: data?.data, message }));
        } else {
          dispatch(todayDealError(error))
        }
      }).catch((error) => {
        dispatch(todayDealError(error))
      });
  }
}