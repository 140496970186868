import { createSlice } from "@reduxjs/toolkit";
import { ORDER } from '../helpers/slice.name';

const orderSlice = createSlice({
    name: ORDER,
    initialState: {

        createOrderLoading: false,
        createOrder: {},
        createOrderMessage: '',
        createOrderError: '',

        getOrderHistoryLoading: false,
        getOrderHistory: [],
        getOrderHistoryMessage: '',
        getOrderHistoryError: '',

        getOrderLoading: false,
        getSingleOrder: [],
        getOrderMessage: '',
        getOrderError: '',

        //get order products
        getSingleOrderProductLoading: false,
        getSingleOrderProducts: [],
        getOrderProductMessage: '',
        getOrderProductError: '',

        getOrderTrackLoading: false,
        getOrderTrack: [],
        getOrderTrackMessage: '',
        getOrderTrackError: '',

        cancelOrderLoading: false,
        cancelSingleOrder: [],
        cancelOrderMessage: '',
        cancelOrderError: '',

        invoiceLoading: false,
        invoiceDownload: "",
        invoiceMessage: '',
        invoiceError: '',
    },
    reducers: {
        createOrderRequest: (state, action) => {
            state.createOrderLoading = true
            state.createOrderMessage = ''
            state.createOrderError = ''
        },
        createOrderSuccess: (state, action) => {
            state.createOrderLoading = false
            state.createOrder = action.payload.data
            state.createOrderMessage = action.payload.message
        },
        createOrderError: (state, action) => {
            state.createOrderLoading = false
            state.createOrderError = action.payload.message
        },
        createOrderReset: (state, action) => {
            state.createOrderMessage = ''
            state.createOrderError = ''
        },

        getOrderHistoryRequest: (state, action) => {
            state.getOrderHistoryLoading = true
            state.getOrderHistoryMessage = ''
            state.getOrderHistoryError = ''
        },
        getOrderHistorySuccess: (state, action) => {
            state.getOrderHistoryLoading = false
            state.getOrderHistory = action.payload.data
            state.getOrderHistoryMessage = action.payload.message
        },
        getOrderHistoryError: (state, action) => {
            state.getOrderHistoryLoading = false
            state.getOrderHistoryError = action.payload.message
        },
        getOrderHistoryReset: (state, action) => {
            state.getOrderHistoryMessage = ''
            state.getOrderHistoryError = ''
        },

        //get single order
        getSingleOrderRequest: (state, action) => {
            state.getOrderLoading = true
            state.getOrderMessage = ''
            state.getOrderError = ''
        },
        getSingleOrderSuccess: (state, action) => {
            state.getOrderLoading = false
            state.getSingleOrder = action.payload.data
            state.getOrderMessage = action.payload.message
        },
        getSingleOrderError: (state, action) => {
            state.getOrderLoading = false
            state.getOrderError = action.payload.message
        },
        getSingleOrderReset: (state, action) => {
            state.getOrderMessage = ''
            state.getOrderError = ''
        },

        //get single order Products
        getSingleOrderProductsRequest: (state, action) => {
            state.getSingleOrderProductLoading = true
            state.getOrderProductMessage = ''
            state.getOrderProductError = ''
        },
        getSingleOrderProductsSuccess: (state, action) => {
            state.getSingleOrderProductLoading = false
            state.getSingleOrderProducts = action.payload.data
            state.getOrderProductError = action.payload.message
        },
        getSingleOrderProductsError: (state, action) => {
            state.getSingleOrderProductLoading = false
            state.getOrderProductError = action.payload.message
        },
        getSingleOrderProductsReset: (state, action) => {
            state.getOrderProductMessage = ''
            state.getOrderProductError = ''
        },

        getOrderTrackRequest: (state, action) => {
            state.getOrderTrackLoading = true
            state.getOrderTrackMessage = ''
            state.getOrderTrackError = ''
        },
        getOrderTrackSuccess: (state, action) => {
            state.getOrderTrackLoading = false
            state.getOrderTrack = action.payload.data
            state.getOrderTrackMessage = action.payload.message
        },
        getOrderTrackError: (state, action) => {
            state.getOrderTrackLoading = false
            state.getOrderTrackError = action.payload.message
        },
        getOrderTrackReset: (state, action) => {
            state.getOrderTrackMessage = ""
            state.getOrderTrackError = ""
        },


        //cancel single order
        cancelSingleOrderRequest: (state, action) => {
            state.cancelOrderLoading = true
            state.cancelOrderMessage = ''
            state.cancelOrderError = ''
        },
        cancelSingleOrderSuccess: (state, action) => {
            state.cancelOrderLoading = false
            state.cancelSingleOrder = action.payload.data
            state.cancelOrderMessage = action.payload.message
        },
        cancelSingleOrderError: (state, action) => {
            state.cancelOrderLoading = false
            state.cancelOrderError = action.payload
        },
        cancelSingleOrderReset: (state, action) => {
            state.cancelOrderMessage = ''
            state.cancelOrderError = ''
        },

        invoiceRequest: (state, action) => {
            state.invoiceLoading = true
            state.invoiceMessage = ''
            state.invoiceError = ''
        },
        invoiceSuccess: (state, action) => {
            state.invoiceLoading = false
            state.invoiceDownload = action.payload.data
            state.invoiceMessage = action.payload.message
        },
        invoiceError: (state, action) => {
            state.invoiceLoading = false
            state.invoiceError = action.payload.message
        },
        invoiceReset: (state, action) => {
            state.invoiceMessage = ''
            state.invoiceError = ''
            state.invoiceDownload = ''
        }
    }
})

export const {
    createOrderRequest,
    createOrderSuccess,
    createOrderError,
    createOrderReset,

    getOrderHistoryRequest,
    getOrderHistorySuccess,
    getOrderHistoryError,

    getSingleOrderRequest,
    getSingleOrderReset,
    getSingleOrderError,
    getSingleOrderSuccess,

    getSingleOrderProductsRequest,
    getSingleOrderProductsSuccess,
    getSingleOrderProductsError,
    getSingleOrderProductsReset,

    cancelSingleOrderRequest,
    cancelSingleOrderSuccess,
    cancelSingleOrderError,
    cancelSingleOrderReset,

    invoiceRequest,
    invoiceSuccess,
    invoiceError,
    invoiceReset,

    getOrderTrackRequest,
    getOrderTrackSuccess,
    getOrderTrackError,
    getOrderTrackReset,
} = orderSlice.actions
export default orderSlice.reducer;