import { addressservice } from "../../services/userAddress";
import { addAddressFailure, addAddressRequest, addAddressSuccess, changeAddressError, changeAddressRequest, changeAddressSuccess, deleteAddressFailure, deleteAddressRequest, deleteAddressSuccess, getAddressFailure, getAddressRequest, getAddressSuccess, getAllCities, getAllCountries, getAllStates, primaryAddressFailure, primaryAddressRequest, primaryAddressSuccess, updateAddressFailure, updateAddressRequest, updateAddressSuccess } from "../../slices/addressSlice";

// Fetch all countries
export const fetchCountries = () => {
    return (dispatch) => {
        addressservice.getCountries()
            .then(response => {
                const { data } = response.data;
                dispatch(getAllCountries(data));
            })
            .catch(error => {
                console.error("Error countries:", error);
            });
    };
};

// Fetch all states
export const fetchStates = (countryId) => {
    return (dispatch) => {
        addressservice.getStates(countryId)
            .then(response => {
                const { data } = response.data;
                dispatch(getAllStates(data));
            })
            .catch(error => {
                console.error("Error states:", error);
            });
    };
};

// Fetch all cities
export const fetchCities = (stateId) => {
    return (dispatch) => {
        addressservice.getCities(stateId)
            .then(response => {
                const { data } = response.data;
                dispatch(getAllCities(data));
            })
            .catch(error => {
                console.error("Error cities:", error);
            });
    };
};

// Fetch all userAdress
export const fetchAddress = () => {
    return (dispatch) => {
        dispatch(getAddressRequest())
        addressservice.getAddresses()
            .then(response => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(getAddressSuccess({ data, message }));
                } else {
                    dispatch(getAddressFailure(error));
                }
            })
            .catch(error => {
                dispatch(getAddressFailure(error));
            });
    };
};

export function AddUserAddress(payload) {
    return (dispatch) => {
        dispatch(addAddressRequest())
        addressservice.addAddress(payload)
            .then((response) => {
                const { status, message, data, error } = response.data;
                if (status === 1) {
                    dispatch(addAddressSuccess({ data, message }));
                } else {
                    dispatch(addAddressFailure(error));
                }
            }).catch((error) => {
                dispatch(addAddressFailure(error));
            })
    }
}
export function UpdateAddress(payload) {
    return (dispatch) => {
        dispatch(updateAddressRequest())
        addressservice.updateAddress(payload)
            .then((response) => {
                const { status, message, data, error } = response.data;
                if (status === 1) {
                    dispatch(updateAddressSuccess({ data, message }));
                } else {
                    dispatch(updateAddressFailure(error));
                }
            }).catch((error) => {
                dispatch(updateAddressFailure(error));
            })
    }
}
export function primaryAdd(payload) {
    return (dispatch) => {
        dispatch(primaryAddressRequest())
        addressservice.setPrimaryAdd(payload)
            .then((response) => {
                const { data = {}, status, message, error } = response.data;
                if (status === 1) {
                    dispatch(primaryAddressSuccess({ data, message }));
                } else {
                    dispatch(primaryAddressFailure(error));
                }
            }).catch((error) => {
                dispatch(primaryAddressFailure(error));
            })
    }
}

export function DeleteUserAdd(payload) {
    return (dispatch) => {
        dispatch(deleteAddressRequest())
        addressservice.DeleteAddress(payload)
            .then((response) => {
                const { status, message, error } = response.data;
                if (status === 1) {
                    const data = true;
                    dispatch(deleteAddressSuccess({ message, data }));
                } else {
                    dispatch(deleteAddressFailure(error))
                }
            }).catch((error) => {
                dispatch(deleteAddressFailure(error));
            })
    }
}

export function changeOrderAddress(payload) {
    return (dispatch) => {
        dispatch(changeAddressRequest())
        addressservice.manageOrderAddress(payload)
            .then((response) => {
                const { status, message, data, error } = response.data;
                if (status === 1) {
                    dispatch(changeAddressSuccess({ data, message }));
                } else {
                    dispatch(changeAddressError(error));
                }
            }).catch((error) => {
                dispatch(changeAddressError(error));
            })
    }
}