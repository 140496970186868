import React from 'react'
import { Link } from 'react-router-dom'
const CancelPolicy = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Cancellation Policy Page</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Cancellation Policy Page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="section-b-space layout-light">
                <div className="custom-container container">
                    <div className="row gy-4">
                        <div className="col-12 mb-0">
                            <div className="about-title">
                                <h5 className='mb-2'>Cancellation Policy</h5>
                                <ul className='mt-2'>
                                    <li className='mb-1'>
                                        <p><strong><span>1.</span> Can I change or modify orders that are already placed?
                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>You cannot modify an order after it's placed, but you can cancel it if it's not yet shipped and place a new one.
                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>2.</span> How to cancel orders?
                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Go to 'My Orders,' select the order you wish to cancel, and click 'Cancel Order.' It will be cancelled if it hasn't been shipped.

                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>3.</span> Can I cancel only part of my order?
                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Yes, you can cancel a part of your order before it is dispatched. Go to ‘My Orders’ section, select your order and then select the item which you want to cancel.

                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>4.</span> Why is the cancellation button disabled?
                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            The order has been dispatched and hence it cannot be cancelled, so the cancellation button is disabled.
                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>5.</span> What are the modes of refund available after cancellation?

                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Refunds can be processed through original payment methods like credit/debit cards, net banking,etc.

                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>6.</span> What happens when I have made a payment from a credit card and the order is returned or cancelled?

                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            If your order is returned or cancelled, the payment will be refunded to your credit card.
                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>7.</span> I have cancelled my order. When will I get my refund?

                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Refunds for cancelled orders are usually processed within 10 working days.

                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>8.</span> Will I get the complete refund of the cancelled order?

                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Yes, you will get the complete refund of the amount paid by you for the item cancelled by you.
                                        </p>
                                    </li>
                                </ul>
                                <ul className='mt-1'>
                                    <li className='mb-1'>
                                        <p><strong><span>9.</span> Was my order cancelled by Indiazona?

                                        </strong></p>
                                    </li>
                                    <li className='px-2'>
                                        <p>
                                            Occasionally, our sellers need to cancel orders due to reasons like product unavailability or not meeting our quality standards. When an order is cancelled, we process the refund
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default CancelPolicy