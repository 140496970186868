import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./assets/css/auth.css";
import { useDispatch, useSelector } from "react-redux";
import { resetLogin, resetSignup } from "../slices/auth.slice";
import ToastService from "../helpers/toast-services";
import { useFormik } from "formik";
import { LoginUser, SignUp } from "../middlewares/auth";
import { useTranslation } from "react-i18next";
import ROUTE_URLS from "../config/routes";
import {
  ConfirmPassword,
  email,
  name,
  Pass,
  Password,
} from "../helpers/yup.validation.schema";
import ErrorList from "../Components/error-list/ErrorList";

const Login = () => {
  const [show, setShow] = useState(
    localStorage.getItem("show")
      ? JSON.parse(localStorage.getItem("show"))
      : false
  );
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loading,
    loginLoading,
    loggedInUser,
    loginMessage,
    loginError,
    signUpMessage,
    signUpError,
  } = useSelector((state) => state.auth);
  useEffect(() => {
    if (!loginLoading) {
      if (loggedInUser) {
        if (loginMessage) {
          ToastService.success(loginMessage);
          navigate(ROUTE_URLS.HOME_PAGE, { replace: true });
        }
      }
      // dispatch(resetLogin())
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginLoading, loggedInUser, loginMessage]);

  useEffect(() => {
    if (!loading) {
      if (signUpMessage) {
        navigate(ROUTE_URLS.PHONE, { replace: true });
        ToastService.success(signUpMessage);
      }
    }
  }, [signUpMessage]);

  const handleSubmit = (values) => {
    const payload = {
      email: values.email,
      password: values.password,
      login_by: "email",
      user_type: "customer",
      identity_matrix: "a6e1f053-4e0d-4fd6-9687-08de845794e0",
    };
    dispatch(LoginUser(payload));
  };

  const handleRegisterSubmit = (values) => {
    const payload = {
      email_or_phone: values.email,
      password: values.password,
      password_confirmation: values.cpassword,
      register_by: "email",
      name: values.name,
    };
    dispatch(SignUp(payload));
  };

  const handleChangeForm = (page) => {
    setShow(!show);
    localStorage.setItem("show", !show);
    if (page === 1) {
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      formik.setFieldTouched("email", false);
      formik.setFieldTouched("password", false);
      dispatch(resetLogin());
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      formik.setFieldValue("cpassword", "");
      formik.setFieldTouched("name", false);
      formik.setFieldTouched("email", false);
      formik.setFieldTouched("password", false);
      formik.setFieldTouched("cpassword", false);
      dispatch(resetSignup());
    }
  };
  const LoginSchema = Yup.object({
    email: email,
    password: Password,
  });
  const RegisterSchema = Yup.object({
    email: email,
    password: Pass,
    cpassword: ConfirmPassword,
    name: name,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });

  const regiFormik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      cpassword: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: handleRegisterSubmit,
  });

  return (
    <>
      <div className="indiazona-login">
        <div
          className={`container  ${show ? "right-panel-active" : ""}`}
          id="container">
          {show === true ? (
            <div className="form-container sign-up-container">
              <form
                onSubmit={regiFormik.handleSubmit}
                className="CreateAccount-Form"
              >

                <h2 style={{color:"#3F59A3"}}>Get Started</h2>
                <div className="mt-2">
                  {signUpError && <ErrorList error={signUpError} />}
                </div>
                <span className="mb-2">Create your account</span>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    type="text"
                    name="name"
                    value={regiFormik.values.name}
                    placeholder="Name *"
                    onChange={regiFormik.handleChange}
                    onBlur={regiFormik.handleBlur}
                  />
                  {regiFormik.touched.name && regiFormik.errors.name && (
                    <div className="error"> {regiFormik.errors.name}</div>
                  )}
                </div>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    type="email"
                    name="email"
                    value={regiFormik.values.email}
                    placeholder="Email *"
                    onChange={regiFormik.handleChange}
                    onBlur={regiFormik.handleBlur}
                  />
                  {regiFormik.touched.email && regiFormik.errors.email && (
                    <div className="error"> {regiFormik.errors.email}</div>
                  )}
                </div>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    value={regiFormik.values.password}
                    type={showPass ? "text" : "password"}
                    name="password"
                    placeholder="Password *"
                    onChange={regiFormik.handleChange}
                    onBlur={regiFormik.handleBlur}
                  />
                  <i
                    className={`fa-solid ${showPass ? "fa-eye-slash" : "fa-eye"
                      } eye-icon `}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPass(!showPass)}
                  ></i>
                  {regiFormik.touched.password && regiFormik.errors.password && (
                    <div className="error"> {regiFormik.errors.password}</div>
                  )}
                </div>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    value={regiFormik.values.cpassword}
                    type={showPass2 ? "text" : "password"}
                    name="cpassword"
                    placeholder="Confirm Password *"
                    onChange={regiFormik.handleChange}
                    onBlur={regiFormik.handleBlur}
                  />
                  <i
                    className={`fa-solid ${showPass2 ? "fa-eye-slash" : "fa-eye"
                      } eye-icon `}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPass2(!showPass2)}
                  ></i>
                  {regiFormik.touched.cpassword &&
                    regiFormik.errors.cpassword && (
                      <div className="error"> {regiFormik.errors.cpassword}</div>
                    )}
                </div>
                <button
                  className="btn my-2 common-btn Login-btn w-100"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Next"}
                </button>
              </form>
            </div>
          ) : null}
          {show === false ? (
            <div className="form-container sign-in-container">
              <form onSubmit={formik.handleSubmit}>
                <h2 style={{color:"#3F59A3"}} className="mb-0 pb-0" >Welcome Back!</h2>
                
                <span className="mb-2 mt-0">sign in to access your account</span>
                <div className="mt-1">
                  {loginError && <ErrorList error={loginError} />}
                </div>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    type="email"
                    value={formik.values.email}
                    name="email"
                    placeholder="Email *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="error"> {formik.errors.email}</div>
                  )}
                </div>
                <div className="password-input mb-2">
                  <input
                    className="m-0"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    name="password"
                    placeholder="Password *"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <i
                    className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"
                      } eye-icon `}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(!showPassword)}
                  ></i>
                  {formik.touched.password && formik.errors.password && (
                    <div className="error"> {formik.errors.password}</div>
                  )}
                </div>
                <Link to="/forgot-password" className="py-2">
                  Forgot your password?
                </Link>
                <button
                  className="btn common-btn Login-btn w-100"
                  type="submit"
                  disabled={loginLoading}
                >
                  {loginLoading ? "Processing..." : "Log In"}
                </button>
              </form>
            </div>
          ) : null}
          <div className="overlay-container">
            <div className="overlay">
              {show === true ? (
                <div className="overlay-panel overlay-left">
                  <div className="india-zona-login">
                    <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                  </div>
                  {/* <h4>Already</h4> */}
                  <p className="py-2">
                    Already have an account?
                  </p>
                  <button
                    className="btn common-btn Login-btn"
                    type="button"
                    onClick={() => handleChangeForm(1)}
                  >
                    Log in
                  </button>
                </div>
              ) : (
                <div className="overlay-panel overlay-right">
                  <div className="india-zona-login">
                    <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                  </div>
                  {/* <h4>New Member?</h4> */}
                  <p className="py-2">
                  Don't have account?
                  </p>
                  <button
                    className="btn my-2  common-btn Login-btn "
                    type="button"
                    onClick={() => handleChangeForm(0)}
                  >
                    Create account
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
