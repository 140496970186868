import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ProductListBreadcrumb from '../Components/Products/ProductListBreadcrumb';
import FilterProductList from '../Components/Products/FilterProductList';
import ProductListData from '../Components/Products/ProductListData';

import { Dropdown } from 'primereact/dropdown';
import { useSelector } from 'react-redux';
import LocalstorageService from '../helpers/localstorage-services';
import { LOCALSTORAGE_CATEGORIES_KEY } from '../config/constants';
import { useDispatch } from 'react-redux';
import { fetchProducts } from '../middlewares/products/product';

const ProductList = () => {
    const dispatch = useDispatch();    
    const { category } = useParams();
    const [selectedSort, setSelectedSort] = useState(null);
    const catList = LocalstorageService.getInLocalStorage(LOCALSTORAGE_CATEGORIES_KEY) || []
    const { homeCategory } = useSelector(state => state.category);


    
    const categoryLabel = category
    const breadcrumbs = [
        { label: 'Home', path: '/', active: false },
        { label: categoryLabel, path: `/category/${category}`, active: true }
    ];
    const sortList = [
        // { name: 'Recommended', code: 'NY' },
        { name: "What's New", code: 'new_arrival' },
        // { name: 'Popularity', code: 'IST' },
        { name: 'Price: High to Low', code: 'price_high_to_low' },
        { name: 'Price: Low to High', code: 'price_low_to_high' },
        // { name: 'Customer Rating', code: 'PRS' },
    ];

    useEffect(() => {
        if (categoryLabel) {
            const categoryId = catList.find(o => o?.slug === categoryLabel)?.id;
            const filter = [{
                category_id: categoryId,
                sort_by: selectedSort?.code,
                sub_categories: [],
            }];

            const formData = new FormData();
            formData.append("filter", JSON.stringify(filter));
            dispatch(fetchProducts(formData));
        }
    }, [categoryLabel, selectedSort]);

    return (
        <div>

            <main>
                <section className="listing-wrapper-section">
                    <div className="container-fluid">
                        <ProductListBreadcrumb breadcrumbs={breadcrumbs} />
                        <div className="row">
                            <FilterProductList />
                            <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                <div className="box-shadow condition-listing-vertical-Filters listing-view-section">
                                    <div className="categories-list-hedding flex  justify-content-between">
                                        <div className="categories-listing-content">
                                            <h6>
                                                {catList.length > 0 ? catList.find(o => categoryLabel === o.slug)?.name : ''}
                                                {/* <span className="f11-listing">(Showing 1 – 40 products of 14,369
                                                    products)</span>  */}
                                            </h6>
                                        </div>
                                        <div className="select-option section-drop prime-drop-custom">
                                            <span className='w-100' style={{ color: '#81878C', fontSize: 14 }}>Sort by</span>
                                            <Dropdown placeholder="Featured"
                                                value={selectedSort} onChange={(e) => setSelectedSort(e.value)}
                                                options={sortList} optionLabel="name" />
                                        </div>
                                    </div>
                                    <ProductListData categoryLabel={categoryLabel} />
                                    {/* <Pagination /> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <div className="mobile-Filters">
                        <button className="btn filter-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#shortTop" aria-controls="shortTop">
                            <i className="fa-solid fa-arrow-up-wide-short px-2" />
                            Sort By
                        </button>
                        <button className="btn filter-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#Filter-right" aria-controls="Filter-right">
                            <i className="fa-solid fa-filter px-2" />
                            Filter
                        </button>
                    </div> */}
                </section>
            </main>
        </div>
    )
}

export default ProductList