import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { phone_number } from '../helpers/yup.validation.schema';
import { sendPhoneNumberOtp } from '../middlewares/auth';
import ROUTE_URLS from '../config/routes';
import ToastService from '../helpers/toast-services';
import ErrorList from '../Components/error-list/ErrorList';


const MobileNumber = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sendMobileOtpLoading, sendMobileOtpMessage, sendMobileOtpError } = useSelector((state) => state.auth);
    const userData = localStorage.getItem('IN_USER_DATA');
    const user = JSON?.parse(userData);
    const { id } = user || {};

    useEffect(() => {
        if (!id) {
            ToastService.error('User ID not found. Please log in again.');
            navigate(ROUTE_URLS.HOME, { replace: true });
        }
    }, [id, navigate]);

    useEffect(() => {
        if (!sendMobileOtpLoading) {
            if (sendMobileOtpMessage) {
                ToastService.success(sendMobileOtpMessage);
                navigate(ROUTE_URLS.VERIFY_MOBILE_OTP, { replace: true });
            }
        }
    }, [sendMobileOtpMessage]);

    const handleSubmit = (values) => {
        const payload = {
            user_id: id,
            phone_code: values.phone_code,
            phone: values.phone,
        }
        dispatch(sendPhoneNumberOtp(payload));
    }

    const mobileSchema = Yup.object({
        phone: phone_number
    });

    const formik = useFormik({
        initialValues: {
            phone_code: '',
            phone: ''
        },
        validationSchema: mobileSchema,
        onSubmit: handleSubmit,
    });

    const handlePhoneChange = (value, country) => {
        formik.setFieldValue('phone_code', `+${country.dialCode}`);
        formik.setFieldValue('phone', value.slice(country.dialCode.length));
    };

    return (
        <div className='indiazona-login'>
            <div className='container right-panel-active' id="container">
                <div className="form-container sign-up-container">
                    <form onSubmit={formik.handleSubmit} className='CreateAccount-Form'>
                        <h4 className='mb-4'>Enter your mobile number</h4>
                        {sendMobileOtpError && <ErrorList error={sendMobileOtpError} />}
                        <div className='password-input mb-2 phone-flag'>
                            <PhoneInput
                                inputClass='w-100 phone-flag-input'
                                inputExtraProps={{
                                    name: 'phone',
                                    required: true,
                                    autoFocus: true,
                                }}
                                placeholder='Mobile Number'
                                country={'in'}
                                onChange={handlePhoneChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="error">{formik.errors.phone}</div>
                            ) : null}
                        </div>
                        <button className='my-2 common-btn Login-btn w-100' type='submit' disabled={sendMobileOtpLoading}>
                            {sendMobileOtpLoading ? 'Processing...' : 'Send OTP'}
                        </button>
                    </form>
                </div>
                <div className="overlay-container">
                    <div className="overlay">
                        <div className="overlay-panel overlay-left">
                            <div className="india-zona-login">
                                <img src="/assets/images/svg-img/logo.svg" alt="logo" />
                            </div>
                            <p className='py-2'>Please enter your mobile number!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MobileNumber