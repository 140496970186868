import { createSlice } from "@reduxjs/toolkit";
import { CATEGORY } from "../helpers/slice.name";

export const categorySlice = createSlice({
    name: CATEGORY,
    initialState: {
        loading: false,
        homeCategoryLoading: false,
        homeCategory: [],
        homeCategoryMessage: "",
        homeCategoryError: "",

        //sub categaries fatch
        subCategoryLoading: false,
        subCategory: [],
        subCategoryMessage: "",
        subCategoryError: "",

        //all categaries fatch
        allCategoryLoading: false,
        allCategory: [],
        allCategoryMessage: "",
        allCategoryError: "",
    },
    reducers: {
        homeCategoryRequest: (state) => {
            state.homeCategoryLoading = true;
            state.homeCategoryMessage = "";
            state.homeCategoryError = "";
        },
        homeCategorySuccess: (state, action) => {
            state.homeCategoryLoading = false;
            state.homeCategory = action.payload.data;
            state.homeCategoryMessage = action.payload.message;
        },
        homeCategoryError: (state, action) => {
            state.homeCategoryLoading = false;
            state.homeCategoryError = action.payload;
        },

        //fatch sub cate
        subCategoryRequest: (state) => {
            state.subCategoryLoading = true;
            state.subCategoryMessage = "";
            state.subCategoryError = "";
        },
        subCategorySuccess: (state, action) => {
            state.subCategoryLoading = false;
            state.subCategory = action.payload.data;
            state.subCategoryMessage = action.payload.message;
        },
        subCategoryError: (state, action) => {
            state.subCategoryLoading = false;
            state.subCategoryError = action.payload;
        },

        //get all categaries
        allCategoryRequest: (state) => {
            state.allCategoryLoading = true;
            state.allCategoryMessage = "";
            state.allCategoryError = "";
        },
        allCategorySuccess: (state, action) => {
            state.allCategoryLoading = false;
            state.allCategory = action.payload.data;
            state.allCategoryMessage = action.payload.message;
        },
        allCategoryError: (state, action) => {
            state.allCategoryLoading = false;
            state.allCategoryError = action.payload;
        },
    }
})
export const { homeCategoryRequest, homeCategorySuccess, homeCategoryError,
    subCategoryError, subCategorySuccess, subCategoryRequest,
    allCategoryError,
    allCategorySuccess,
    allCategoryRequest
} = categorySlice.actions;

export default categorySlice.reducer;