import bankaccountService from "../services/accountDetails";
import { accountError, accountRequest, accountSuccess } from "../slices/accountSlice";

export function bankDetails(payload) {
    return (dispatch) => {
        dispatch(accountRequest());
        bankaccountService.bankDetails(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(accountSuccess({ data, message }));
                } else {
                    dispatch(accountError({ error }));
                }
            }).catch((error) => {
                dispatch(accountError(error));
            })
    }
}
