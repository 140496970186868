import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { EffectCards, Autoplay, EffectCube } from 'swiper/modules';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { fetchShortVideo } from '../../middlewares/shortVideo';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { SELLER_ADD } from '../../BaseUrl';

const HeroSection = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shortVideos = useSelector((state) => state.shortvideo.shortVideo);
    useEffect(() => {
        dispatch(fetchShortVideo());
    }, []);
    
    const handleNavigate = (id) => {
        navigate("/short-videos", {
            state: {
                id: id
            }
        });
    }

    return (
        <section className="hero-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="hero-inner">
                            <div className="row hero-wpr">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="hero-content">
                                        <div className="hero-content-inner">
                                            <h3>
                                                Vendor Registration <br /> In Progress
                                            </h3>
                                            <div
                                                // whileHover={{ scale: 1.1 }} 
                                                className='my-3'
                                            // whileTap={{ scale: 0.9 }}
                                            >
                                                <button className='btn common-btn shopBtn' onClick={() => window.open(SELLER_ADD, '_blank')}>
                                                    Become a Vendor
                                                </button>
                                            </div>
                                            <h6 className='text-white'>
                                                {/* On National Brands */}
                                                Launching soon for customers
                                            </h6>
                                            {/* <motion.div whileHover={{ scale: 1.1 }}
                                                whileTap={{ scale: 0.9 }}>
                                                <button className='btn common-btn shopBtn' onClick={() => navigate('/short-videos')}>
                                                    Swipe & Buy
                                                </button>
                                            </motion.div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-10 mx-auto">
                                    <div className="hero-reels">
                                        <Swiper
                                            autoplay={{ delay: 2000 }}
                                            loop={true}
                                            effect={'cards'}
                                            // grabCursor={true}
                                            className="mySwiper"
                                            cardsEffect={{
                                                shadow: false,
                                                slideShadows: false,
                                            }}
                                            modules={[EffectCards, Autoplay]}
                                            lazy="true"
                                        >
                                            {shortVideos.slice(17, 20).map((e) => (
                                                <SwiperSlide key={e?.id}>
                                                    <div className="video-inner" onClick={() => handleNavigate(e?.id)}>
                                                        <LazyLoadImage src={e.photos[0]} alt="Short Video"
                                                            loading="lazy" effect="blur"
                                                            className='hero-section-img swiper-lazy' />
                                                        <i className="fa-solid fa-circle-play" />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-md-6  col-lg-12 col-sm-12">
                                <div className="hero-product">
                                    <div className="row d-flex aligin-items-center" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-md-6 d-flex aligin-items-center">
                                            <div className="hero-product-inner">
                                                <div className="img-flag-box" >
                                                    <LazyLoadImage src="/assets/images/madeIndia.png" alt="india-Flag-img" effect="blur" />
                                                </div>
                                                <h5>
                                                    Made in India Products
                                                </h5>
                                                <div className="shop-btn">
                                                    <Link to={``} className="d-flex align-items-center gap-2">
                                                        <span>Shop Now</span>
                                                        <i className="fa-solid fa-arrow-right right-arrow-icon" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="hero-product-img">
                                                <LazyLoadImage src="/assets/images/Product/Bangles.png" alt="Bangles" effect="blur" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6  col-lg-12 col-sm-12">
                                <div className="product-Handmade">
                                    <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="col-md-6 order-lg-0 order-md-0 order-2">
                                            <div className="product-Handmade-img ">
                                                <LazyLoadImage src="/assets/images/Product/p-1.png" alt="product" effect="blur" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 order-lg-0 order-md-0 order-1">
                                            <div className="Product-name ">
                                                <p className="text-end text-white">
                                                    We Shop
                                                </p>
                                                <h5 className="text-end text-white" >
                                                    Handmade product of India
                                                </h5>
                                                <div className="shop-btn" >
                                                    <Link to='' className="d-flex align-items-center gap-2 text-center justify-content-end">
                                                        <span>Shop Now</span>
                                                        <i className="fa-solid fa-arrow-right right-arrow-icon" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default HeroSection