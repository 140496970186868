import React, { useState, useEffect } from 'react';
import PageTitle from '../Components/Products/PageTitle';
import CartDetails from '../Components/Products/CartDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createOrderReset } from '../slices/orderSlice';

const Cart = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const data = location.state;
    const [modalOpen, setModalOpen] = useState(false);
    const { createOrder } = useSelector((state) => state?.order)

    useEffect(() => {
        if (data) {
            setModalOpen(true);
            dispatch(createOrderReset());
        }
    }, [data, dispatch, createOrder]);

    const handleModalClose = () => {
        setModalOpen(false);
    };
    return (
        <div>
            <main>
                <PageTitle name={'Shopping Cart'} />
                <CartDetails />
                <Modal
                    show={modalOpen}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                    keyboard={false}
                    onHide={handleModalClose}
                >
                    <Modal.Header closeButton>
                        {/* <Modal.Title>Items Details</Modal.Title> */}
                    </Modal.Header>
                    <Modal.Body>
                        <div className='success-content'>
                            <div className='success-conten-img'>
                                <img src="/assets/images/svg-img/Group.svg" alt="check-img" />
                            </div>
                            <h5 className='mb-3 text-center'>Your order has been successfully placed.</h5>
                            <p className='text-center mb-2'>Order ID: {createOrder?.code}</p>
                            {/* <p className='text-center mb-4'>Expected Delivery: September 20, 2024</p> */}
                            <div className='row justify-content-center'>
                                <div className="col-5 success-conten-button">
                                    <button className='w-100 btn' onClick={() => navigate('/order-details', { state: {Id: createOrder?.id} })}>Track Order</button>
                                    {/* <button className='w-100 btn' onClick={() => navigate('/user-orders')}>Track Your Address</button> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </main>
        </div>
    );
};

export default Cart;
