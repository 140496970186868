import { createSlice } from "@reduxjs/toolkit";
import { BUY_NOW } from "../helpers/slice.name";

export const buyNowSlice = createSlice({
    name: BUY_NOW,
    initialState: {
        item:[],
    },
    reducers: {

        buyNowAddProduct:(state,action)=>{
            state.item={...action.payload};
        },
        // buyNowRemoveProduct:(state,action)=>{
        //     state.item=[];
        // }
       
    }
})

export const {
    buyNowAddProduct

} = buyNowSlice.actions;

export default buyNowSlice.reducer;