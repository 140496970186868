import React, { useEffect, useState } from 'react'

function FlashSale() {
    const endDate = "2024-11-30T23:59:59"; //sale end date hard coded

        const calculateTimeLeft = () => {
       
          const now = new Date();
          const targetDate = new Date(endDate);
          const difference = targetDate - now;
      
          if (difference <= 0) {
            return { days: '00', hours: '00', minutes: '00', seconds: '00' };
          }
      
          const days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
          const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
          const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
          const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');
      
          return { days, hours, minutes, seconds };
        };
      
        const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
          }, 1000);
      
          return () => clearInterval(intervalId);
        }, [endDate]);




    return (
        <>
            <section className='FlashShale-section wrapper-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xxl-4 col-lg-4 col-md-5 col-sm-12">
                            <div className="FlashShale-inner-content">
                                <h6>FLASH SALE!</h6>
                                <h5>Handmade <br /> Circular Designs</h5>
                                <p>Beautifully Crafted Indian Art Featuring Gods, Perfect for Adding Spirituality and Elegance to Your Home.</p>
                                <ul className='flash-ul'>
                                    <li>
                                        <b>{timeLeft.days}</b>
                                        <small>Days</small>
                                    </li>
                                    <li>
                                        <b>{timeLeft.hours}</b>
                                        <small>Hours</small>
                                    </li>
                                    <li>
                                        <b>{timeLeft.minutes}</b>
                                        <small>Minutes</small>
                                    </li>
                                    <li>
                                        <b>{timeLeft.seconds}</b>
                                        <small>Seconds</small>
                                    </li>
                                </ul>
                                <div className="col-lg-6 col-md-8 col-sm-12 mt-lg-5 mt-md-4  mt-3">
                                    <button className='btn shop-now-btn'>Shop Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-lg-8 col-md-7 col-sm-12">
                        <div className="FlashShale-inner-img">
                                <img src="/assets/images/flash-img.png" alt="flash-img" />
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FlashSale
