import { get, post } from ".";

const URI = "/categories";

const getHomeCategories = (payload) => {
    const URL = `${URI}/homeCategories`;
    return get(URL, payload);
};

const getSubCategories = (payload) => {
    const URL = `/products/get-filter-sidebar`;
    return post(URL, payload);
};

const allSubCategories = () => {
    const URL = `${URI}/all`;
    return get(URL);
};

const CategoryService = {
    getHomeCategories,
    getSubCategories,
    allSubCategories
}

export default CategoryService;