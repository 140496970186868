import React, { useEffect, useState } from 'react'
import OrderTotal from '../Comman/OrderTotal'
import DeliveryStatus from '../../../Components/Products/Order/DeliveryStatus'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { downloadInvo } from '../../../middlewares/cart/order'
import { useFormik } from 'formik'
import * as Yup from "yup";
import {
    acountHoldername,
    accountNo,
    IFSCcode,
    cAccountNo
} from "../../../helpers/yup.validation.schema";
import { bankDetails } from '../../../middlewares/bankAcount'
import { accountReset } from '../../../slices/accountSlice'
import ToastService from '../../../helpers/toast-services'
import { Modal } from 'react-bootstrap'
import AddBankDetailsModal from '../../../Components/Model/OrderTrack/AddBankDetailsModal'
import { trackReturnOrder } from '../../../middlewares/Order/returnRequest'
import { toast } from 'react-toastify'
import { invoiceReset } from '../../../slices/orderSlice'
import ChangeAddressModal from '../../../Components/Model/OrderTrack/ChangeAddressModel'
import { resetRequestReset } from '../../../slices/returnRequest'
import { addAddressReset } from '../../../slices/addressSlice'

const ReturnDetails = () => {
    const userDeta = JSON.parse(localStorage.getItem('IN_USER_DATA'))
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const IsLogedin = localStorage.getItem('IN_USER_DATA');
    useEffect(() => {
        !IsLogedin ? navigate('/') : <></>;
    }, [IsLogedin]);
    const [modalOpen, setModalOpen] = useState(false);
    const [addressModal, setAddressModel] = useState(false);
    const handleModalClose = () => {
        formik.resetForm();
        setModalOpen(false);
    };
    const returnOrder = () => {
        setModalOpen(true);
    };
    const data = location.state;

    const { invoiceDownload, invoiceMessage } = useSelector((state) => state?.order);
    const { accountLoading, accountMessage, accountError } = useSelector((state) => state?.accountDetails);
    const { getreturnRequestData, getreturnRequestMessage } = useSelector((state) => state?.returnRequest);
    const { changeAddressMessage } = useSelector((state) => state.address);
    const parsedAddress = getreturnRequestData?.order?.shipping_address ? JSON.parse(getreturnRequestData?.order?.shipping_address) : null;
    const productData = getreturnRequestData?.order?.orderDetails

    // console.log(parsedAddress, 'getreturnRequestData');

    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", data?.order_id);
        bodyFormData.append("order_detail_id", data?.order_detail_id);
        dispatch(trackReturnOrder(bodyFormData));
    }, [dispatch]);

    useEffect(() => {
        if (changeAddressMessage) {
            const bodyFormData = new FormData();
            bodyFormData.append("id", data?.order_id);
            bodyFormData.append("order_detail_id", data?.order_detail_id);
            dispatch(trackReturnOrder(bodyFormData));
            dispatch(addAddressReset())
        }
    }, [dispatch, changeAddressMessage]);

    const handleModalShow = () => {
        setModalOpen(true);
    };
    useEffect(() => {
        if (accountError) {
            ToastService.error(accountError);
            dispatch(accountReset())
        }
        if (accountMessage) {
            ToastService.success(accountMessage);
            dispatch(accountReset())
        }
    }, [accountError, accountMessage, dispatch]);
    useEffect(() => {
        if (invoiceMessage) {
            toast.success(invoiceMessage);
            window.open(invoiceDownload, '_blank');
            dispatch(invoiceReset());
        }
    }, [invoiceMessage, invoiceDownload, dispatch]);

    const downloadInvoice = () => {
        const bodyFormData = new FormData();
        bodyFormData.append("id", data?.order_id);
        dispatch(downloadInvo(bodyFormData))
    }
    const handleSubmit = (values) => {
        const bodyFormData = new FormData();
        bodyFormData.append('id', data?.id)
        bodyFormData.append('user_id', userDeta?.id)
        bodyFormData.append('account_number', values?.accountNo)
        bodyFormData.append('beneficiary_name', values?.name)
        bodyFormData.append('ifsc_code', values?.IFSCcode)
        handleModalClose()
        dispatch(bankDetails(bodyFormData));
    };
    const AccountSchema = Yup.object({
        name: acountHoldername,
        accountNo: accountNo,
        cAccountNo: cAccountNo,
        IFSCcode: IFSCcode
    });
    const formik = useFormik({
        initialValues: {
            name: "",
            accountNo: "",
            cAccountNo: "",
            IFSCcode: "",
        },
        validationSchema: AccountSchema,
        onSubmit: handleSubmit,
    });
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Return Tracking</h3>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <div className="order-wpr-left ">
                                <div className="order-product">
                                    <img src={productData?.product?.photos[0]?.path} alt="order-img" />
                                </div>
                                <div className="order-content">
                                    <h6>{productData?.product?.brand_name}</h6>
                                    <p>{productData?.product?.name}</p>
                                    <h5>
                                        <span>₹. {productData?.product?.indiazona_price}</span>
                                    </h5>
                                    <div className='w-100 mt-4'>
                                        <button className="btn style-1 btn-primary-filled-slide p-0" type='button' onClick={() => navigate(`/${productData?.product?.category_slug}/product-details/${productData?.product?.id}`)}>
                                            <span>View Product</span>
                                        </button>
                                        {/* <a className="btn style-2 btn-outline-primary-slide p-0">
                        <span>Reorder Product</span>
                      </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-12">
                            <div className="order-wpr-right">
                                <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                        <li className="breadcrumb-item" onClick={() => navigate('/return-orders')} style={{ cursor: 'pointer' }}><Link>Return Orders Details </Link></li>
                                        <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                        <li className="breadcrumb-item" aria-current="page">ID {getreturnRequestData?.order?.code}</li>
                                    </ol>
                                </nav>

                                <div className='row justify-content-between align-items-center mt-2'>
                                    <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                        <div className='order-Id-Number'>
                                            <h4 className='mb-lg-0 mb-md-2 mb-2'>Order ID: {getreturnRequestData?.order?.code}</h4>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                                        <div className="row justify-content-lg-end justify-content-md-end justify-content-sm-start">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-6">
                                                <div className='order-Invoice-btn'>
                                                    <button className='Invoice-btn w-100' onClick={downloadInvoice}><img src="/assets/images/svg-img/Invoice.svg" alt="Invoice" /> Invoice</button>
                                                </div>
                                            </div>
                                            {
                                                getreturnRequestData?.is_return_approved === 1 ? <div className="col-xl-5 col-lg-6 col-md-6 col-6">
                                                    <div className='order-Invoice-btn'>
                                                        <button className='add-bank-details w-100' onClick={handleModalShow} disabled={accountLoading}> {accountLoading ? "proccesing..." : "Add Bank Details"}</button>
                                                    </div>
                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className='row align-items-center mt-2'>
                                    <div className=" col-xxl-3 col-xl-3 col-lg-5 col-md-12 col-sm-12 ">
                                        <div className='order-date mt-md-2 mb-2 mb-lg-0'>
                                            <h4>
                                                <span>Order date: </span> {getreturnRequestData?.order?.order_date}
                                            </h4>

                                        </div>
                                    </div>
                                    <div className="col-xxl-7 col-xl-8 col-lg-6 col-md-12 col-sm-12">
                                        <div className='order-delivery mt-md-2 mt-sm-2'>
                                            <h6 className='delivery-date'>
                                                <img src="/assets/images/svg-img/truck-tick.svg" alt="truck-tick" />
                                                {getreturnRequestData?.estimated_delivery ? `Estimated delivery: ${getreturnRequestData?.estimated_delivery}` : "No estimated delivery available"}
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <DeliveryStatus type={'return'} status={getreturnRequestData?.status} />
                                </div>

                                <div className="View-detailed tracking mt-3">
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#detailed" aria-expanded="false" aria-controls="detailed">
                                                View detailed tracking
                                            </button>
                                            <div id="detailed" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {getreturnRequestData?.track?.map((track, index) => (
                                                        <div className="accordion-content" key={index}>
                                                            <h6>{track.title}</h6>
                                                            {track.message ? (
                                                                <p>{track.message}</p>
                                                            ) : (
                                                                <p>No additional information available</p>
                                                            )}
                                                        </div>
                                                    ))}
                                                    {getreturnRequestData?.track?.length === 0 && (
                                                        <div className="accordion-content">
                                                            <p>No tracking information available</p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="delivery-wpr">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                            <div className="row justify-content-between">
                                                <div className='col-9 delivery-inner'>
                                                    <h6><strong>Delivery</strong></h6>
                                                    <p><small>Address</small></p>
                                                    <>
                                                        {parsedAddress ? (
                                                            <>
                                                                <p>{parsedAddress?.name}</p>
                                                                <p>{parsedAddress?.address}</p>
                                                                <p>{parsedAddress?.postal_code}</p>
                                                                <p>{parsedAddress?.state}, {parsedAddress?.country}</p>
                                                                <p className='mt-lg-4 mt-md-3 mt-1'>Mob: <strong>+91 {parsedAddress?.phone}</strong></p>
                                                            </>
                                                        ) : (
                                                            <p>No shipping address available</p>
                                                        )}
                                                    </>
                                                </div>
                                                <div className='col-3 change-address'>
                                                    <button className="style-2 btn-outline-primary-slide p-0 Change-address-btn" type='button' onClick={() => setAddressModel(true)}>
                                                        <span>Change</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-12 col-sm-12 mb-3">
                                            <div className='order-help-wpr'>
                                                <h6>Need Help</h6>
                                                <div className='Chat-us'>
                                                    <p><span className='img-icons px-1'> <img src="/assets/images/svg-img/Chat-us.svg" alt="chat-us" /> </span> Chat with us  <span className='img-icons px-1'><img src="/assets/images/svg-img/arrow-up.svg" alt="arrow-up" /></span></p>

                                                </div>
                                                {getreturnRequestData?.order?.order_in_transit === 0 && (
                                                    <div className='cancel-order Chat-us'>
                                                        <p style={{ cursor: 'pointer' }} onClick={() => setModalOpen(true)}><span className='img-icons px-1'> <img src="/assets/images/svg-img/cancel-order.svg" alt="cancel-order" /> </span> Cancel Order <span className='img-icons px-1'><img src="/assets/images/svg-img/arrow-up-cancel.svg" alt="arrow-up" /></span></p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <OrderTotal order_summary={getreturnRequestData?.order?.order_summary} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* ====Bank Add modal==== */}

                <AddBankDetailsModal
                    show={modalOpen}
                    handleSubmit={handleSubmit}
                    setModalClose={setModalOpen}
                    setModalOpen={setModalOpen}
                />
                {/* <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content add-bank-modal">
                            <div className="modal-header">
                                <h6 className="modal-title text-center" id="exampleModalToggleLabel">Add Account details</h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <ChangeAddressModal
                mode={returnOrder}
                data={data}
                show={addressModal}
                onHide={() => setAddressModel(false)}
            />
        </>
    )
}

export default ReturnDetails