import React from 'react'
import TopBar from '../Components/Common/TopBar'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const BlogDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const doodlesData = useSelector((state) => (state.doodle.doodles));
  const data = location.state
  return (
    <>
      <main>
        {/* <section className="page-title">
                    <div className="pattern-layer"></div>
                    <div className="container-full">
                        <div className="content-box">
                            <h3>Blog Details</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">/</li>
                                    <li className="breadcrumb-item" aria-current="page">Blog Details Page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section> */}
        <section className="blog-detail-section">
          <div className="container-fluid">
            <div className="row justify-content-between">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="blog-detail-wrapper">

                  <img src={`${data?.header_image}`} alt="blog-detail-img" />
                </div>
                <div className="blog-detail-content mt-2 mb-2">
                  {/* <h6>
                                        {data?.elem?.title}
                                    </h6>
                                    <p>
                                        {data?.elem?.description ? (
                                            <p dangerouslySetInnerHTML={{ __html: data?.elem?.description }} />
                                        ) : (
                                            <p className="f-14 mb-1">Description not available.</p>
                                        )}
                                    </p> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className='blog-detail-contain'>
                  <h6>
                    {data?.title}
                  </h6>
                  <p>
                    {data?.description ? (
                      <p dangerouslySetInnerHTML={{ __html: data?.description }} />
                    ) : (
                      <p className="f-14 mb-1">Description not available.</p>
                    )}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </section>
        {/* =======blog-detail-section======End */}

        <section className="article-section">
          <div className="container-fluid">

            <div className="row">
              <Swiper
                modules={[Autoplay]}
                spaceBetween={30}
                slidesPerView={4}
                autoplay={{ delay: 2500 }}
                loop={true}
                pagination={{ clickable: true }}
                navigation={true}
                breakpoints={{
                  320: { slidesPerView: 1 },
                  640: { slidesPerView: 2 },
                  768: { slidesPerView: 2 },
                  1024: { slidesPerView: 4 },
                  1200: { slidesPerView: 4 },
                }}>
                {
                  doodlesData.map(elem =>
                    <SwiperSlide>
                      <div className="blog-article-item">
                        <div className="article-thumb">
                          <a to='/blog-details'>
                            <img className="lazyload" src={`${elem?.header_image}`} onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }} alt="img-blog" />
                          </a>
                          <div className="article-label">
                            <span onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }} className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</span>
                          </div>
                        </div>
                        <div className="article-content">
                          <div className="article-title">
                            <span onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }}  >{elem?.title}</span>
                          </div>
                          <div className="article-btn">
                            <span onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }} className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                }
              </Swiper>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default BlogDetails