import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changeOrderAddress, fetchAddress } from '../../../middlewares/UserProfile/address';
import { getOrderTracking } from '../../../middlewares/cart/order';
import { changeAddressReset } from '../../../slices/addressSlice';
const ChangeAddressModal = ({ data, show, onHide }) => {
    const dispatch = useDispatch();
    const IsLogedin = localStorage.getItem('IN_USER_DATA');
    const userDetails = JSON.parse(IsLogedin);
    const { userAddress, changeAddressMessage } = useSelector((state) => state.address);
    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };
    useEffect(() => {
        if (show === true) return dispatch(fetchAddress());
    }, [show, dispatch]);
    useEffect(() => {
        if (changeAddressMessage) {
            const bodyFormData = new FormData();
            bodyFormData.append("id", data?.order_id);
            dispatch(getOrderTracking(bodyFormData));
            dispatch(changeAddressReset())
        }
    }, [changeAddressMessage]);
    const changeAddress = () => {
        if (selectedAddress) {
            const bodyFormData = new FormData();
            bodyFormData.append("order_id", data?.order_id);
            bodyFormData.append("address_id", selectedAddress.id);
            bodyFormData.append("user_name", selectedAddress.customer_name);
            bodyFormData.append("user_email", userDetails.email);
            dispatch(changeOrderAddress(bodyFormData));
            toast.success('Address has been changed');
            onHide()
        } else {
            toast.success('Please select an address');
        }
    };
    return (
        <Modal
            show={show}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            size="md"
            keyboard={false}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <h6>Change order address</h6>
            </Modal.Header>
            <Modal.Body>
                <div className='address-selection'>
                    {userAddress?.map((e, i) => (
                        <div key={e.id}
                            style={{ padding: '10px', border: '1px solid #ccc', marginBottom: '10px' }}>
                            <div className="form-check Change-address">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedAddress?.id === e.id}
                                    onChange={() => handleAddressChange(e)}
                                />
                                <label className="form-check-label" htmlFor={`address-${e.id}`}>
                                    <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                        <li><strong>{e?.customer_name}</strong></li>
                                        <li>{e.address}, <span className="mx-1">{e.address_type}</span></li>
                                        <li>{e.city} - {e.postal_code}</li>
                                        <li>{e.state}, {e.country}</li>
                                        <li>{e.phone_code} {e.phone}</li>
                                    </ul>
                                </label>
                            </div>
                        </div>
                    ))}
                    {userAddress?.length === 0 && <p>No addresses available.</p>}
                </div>
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-4 success-conten-button d-flex justify-content-between">
                            <button className='w-100 btn' onClick={onHide}>No</button>
                        </div>
                        <div className="col-4 success-conten-button d-flex justify-content-between">
                            <button className='w-100 btn btn-outline' onClick={changeAddress}>Yes</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ChangeAddressModal