import { get, post, postFormData } from ".";

const chatReciever = (payload) => {
    const URL = `chat-receiver-id`;
    return get(URL, payload);
};

const sendChat = (payload) => {
    const URL = `chat-messages`;
    return postFormData(URL, payload);
}
const getChat = (payload) => {
    const URL = `get-chat-messages`;
    return get(URL, payload);
}

const chatService = {
    chatReciever,
    sendChat,
    getChat
};
export default chatService;