import React, { useEffect } from 'react'
import { getSingleOrderData } from '../../../middlewares/cart/order';
import { useDispatch, useSelector } from 'react-redux';

const OrderTotal = ({ order_summary, product }) => {
    const { getOrderTrack } = useSelector((state) => state?.order);
    const { order } = getOrderTrack;
    const productData = order?.orderDetails?.find((o) => o.product.id === product)

    return (
        <>
            <div className='order-summary'>
                <h5>Order Summary</h5>
                <ul>
                    <li className='d-flex justify-content-between align-items-center mt-2'>
                        <h6>MRP</h6>
                        <h6><strong> {productData?.single_summary?.sub_total}</strong></h6>
                    </li>
                    <li className='d-flex justify-content-between align-items-center mt-2'>
                        <p>Discount</p>
                        <p>- ( {productData?.single_summary?.total_discount}% )</p>
                    </li>
                    <li className='d-flex justify-content-between align-items-center mt-2'>
                        <p>Delivery Charges</p>
                        <p> 0.00</p>
                    </li>
                    <li className='d-flex justify-content-between align-items-center mt-2'>
                        <p>Tax</p>
                        <p>+  {productData?.single_summary?.tax}</p>
                    </li>
                    <div className='total-amount d-flex justify-content-between align-items-center'>
                        <h6>Total</h6>
                        <h6>₹ {productData?.single_summary?.total_amount}</h6>
                    </div>
                </ul>
            </div>
        </>
    )
}

export default OrderTotal