import { postFormData } from ".";

const URI = "/products";

const productsDetails = (payload) => {
    const URL = `${URI}/product-listing`;
    return postFormData(URL, payload);
};

const productsDetailsById = (payload) => {
    const URL = `${URI}/product-details`;
    return postFormData(URL, payload);
};

const getTodaysDeal = (payload) => {
    const URL = `${URI}/todays-deal`;
    return postFormData(URL, payload);
}

export const ProductService = {
    productsDetails,
    productsDetailsById,
    getTodaysDeal,
}