import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';



const RemoveModal = ({ setShow,show,handleRemoveCartItem, id,setCartItemRemoveId }) => {
    
    const handleClose = () => {
        setShow(false);
        setCartItemRemoveId(null);
    };


    return (
        <div>
            

            <Modal show={show} onHide={handleClose} centered
            backdrop="static"
            size="md"
            keyboard={false}
            style={{ 'z-index': '20000' }}
            >
            <Modal.Header closeButton ></Modal.Header>

                  <div className="d-flex justify-content-center align-items-center modal-container" >
                        <div className='success-conten-img'>
                            <img src="/assets/images/svg-img/face-icon.svg" alt="check-img" />
                        </div>
                        <h6 className="w-lg-30">Do you really want to remove this product?</h6>
                  </div>
                  <div className="d-flex justify-content-center mb-3 mt-1">

                  <button className="btn mx-1" style={{width:"5rem",height:'2rem',backgroundColor:"#00C53C",border:"#00C53C",color:"white"}} onClick={handleClose}  >
                        No
                    </button>
                  <button className="btn mx-1" style={{width:"5rem",height:'2rem',backgroundColor:"#FF8C00",border:"#FF8C00",color:"white"}} onClick={()=>handleRemoveCartItem(id)}>
                        Yes
                    </button>
                  </div>


            </Modal>
        </div>
    )
}

export default RemoveModal