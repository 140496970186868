import { ArrowRight, MoveRight } from 'lucide-react'
import React from 'react'

function DealDay() {

    const dealData = [
        { id: '1', title: 'Home & Living', name: 'SOFA', img: "/assets/images/deal-1.png" },
        { id: '2', title: 'Jewelry & Accessories', name: 'HANDMADE JEWELRY', img: "/assets/images/deal-2.png" }
    ]
    return (
        <>
            <section className='Deal-section wrapper-section'>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-12">
                            <ul className='border-0'>
                                <li>
                                    <h4 className="Title">Deal of the Day</h4>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        {dealData.map((e, i) =>
                            <div className="col-lg-6 col-md-6 col-sm-12 mt-3" key={i}>
                                <div className="deal-inner-box">
                                    <div className="deal-content">
                                        <p>{e?.title}</p>
                                        <h4>{e?.name}</h4>
                                        <button className='btn btn-deal-banner'>SHOP NOW <span className='px-lg-2 px-md-1 px-1'><ArrowRight /></span> </button>
                                    </div>
                                    <a href="">
                                        <img src={e.img} alt={e.img} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default DealDay
