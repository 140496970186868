import React, { useEffect, useState } from 'react'
import PageTitle from '../../Components/Products/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { MoveLeft, MoveRight, Plus } from 'lucide-react';
import { fetchAddress, primaryAdd } from '../../middlewares/UserProfile/address';
import LocalstorageService from '../../helpers/localstorage-services';
import AddressForm from '../../Components/Model/Cart/AddressForm';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addAddressReset, primaryAddressReset, resetAddaddress } from '../../slices/addressSlice';
import { getCartData } from '../../middlewares/cart/cart';
import CheckOutSummary from '../../Components/Products/CheckOut/CheckOutSummary';
import ROUTE_URLS from '../../config/routes';
import ToastService from '../../helpers/toast-services';
import { IN_URL } from '../../BaseUrl';
import axios from 'axios';
import { buyNowAddProduct } from '../../slices/buyNowSlice';



const ShippingAddress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { method,product_id,color,qty,pickup_id } = useParams();
    const data = location.state
    const user = LocalstorageService.getLoggedInUserDetails();
    const userData = LocalstorageService.getLoggedInUserDetails();
    const [showAddress, setShowAddress] = useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState(null)
    const { userAddress, primary, addAddressMessage, addAddressError, SuccessMessage, primaryAddressError, primaryAddressMessage } = useSelector((state) => state.address);
    const { productDetails, productDetailsLoading, } = useSelector(state => state.product);
    const { cart } = useSelector((state) => state.cart);
    const {item}=useSelector(state => state.buyNow);
    const [order,setOrder]=useState({});
    useEffect(() => {
        if (!userData?.id) {
            navigate(ROUTE_URLS.HOME, { replace: true });
        }

            if(method ==="buy-now" && item.length===0){
            const newFormData = new FormData();
            newFormData.append('user_id',userData?.id);
            newFormData.append('product_id',product_id);
            newFormData.append('quantity',qty);
            newFormData.append('color',color);
            newFormData.append('pickup_addresse_id',pickup_id);
            
            const url=IN_URL +'/cart/buy-now';
            let responseData="";
            const config = {};
            axios
              .post(url, newFormData,{
                "Content-Type": "multipart/form-data",
                ...config,
              })
              .then((response) => {
                responseData= response.data; // Handle successful response
                const cart_item=[productDetails];
                // const data={...responseData,cart_item};
                dispatch(buyNowAddProduct({...responseData,cart_item}));
                
                // navigate(`/checkout/shipping/buy-now`);
                // setError(null); // Clear any previous errors
              })
              .catch((err) => {
                responseData=err.message; // Handle error response
                console.error("Error submitting form data:", err);
                toast.error("Something went wrong! Please try again later");
              });

            }
            
    }, []);



    useEffect(() => {
        if (SuccessMessage) {
            toast.success(SuccessMessage);
            dispatch(fetchAddress());
            dispatch(primaryAddressReset());
            dispatch(addAddressReset());
        }

        // console.log("typeofcart: ",typeof(cart.cart_item));
        // console.log("typeofitem: ",typeof(item.cart_item));
        // console.log("typeofcart: ",cart.cart_item);
        // console.log("typeofitem: ",item);
        // console.log(item?.cart_item[0]?.photos[0].path)
    }, [SuccessMessage, cart]);

    useEffect(() => {
        const delivery_charge = userAddress.find(address => address?.set_default === 1)
        const bodyFormData = new FormData();
        bodyFormData.append("user_id", userData?.id);
        bodyFormData.append("customer_pincode", data === null ? delivery_charge?.postal_code : data);
        bodyFormData.append("is_checkout", 1);
        dispatch(getCartData(bodyFormData));
    }, [dispatch, data])

    const handleDefault = (addressId) => {
        setSelectedAddressId(addressId?.id)
        dispatch(primaryAdd({ id: addressId?.id }));
        if (user) {
            const bodyFormData = new FormData();
            bodyFormData.append("user_id", user.id);
            bodyFormData.append("customer_pincode", addressId?.postal_code || null);
            bodyFormData.append("is_checkout", 1);
            dispatch(getCartData(bodyFormData));
        }
    }
    useEffect(() => {
        const defaultAddress = userAddress.find(address => address?.set_default === 1);
        if (defaultAddress) {
            setSelectedAddressId(defaultAddress?.id);
        }
    }, [userAddress]);

    useEffect(() => {
        dispatch(fetchAddress())
    }, [dispatch, primary]);
    useEffect(() => {
        if (addAddressMessage) {
            dispatch(resetAddaddress())
        }
        if (addAddressError) {
            ToastService.error(addAddressError);
            dispatch(resetAddaddress())
        }
        if (primaryAddressMessage) {
            ToastService.success(primaryAddressMessage)
            dispatch(primaryAddressReset())
        }
        if (primaryAddressError) {
            ToastService.error(primaryAddressError)
            dispatch(primaryAddressReset())
        }
    }, [primaryAddressMessage, primaryAddressError, addAddressMessage, addAddressError]);
    const handleNextStep = () => {

        const findAddress = userAddress?.find((us) => us?.set_default === 1);
        if (findAddress) {
            if(method=="buy-now"){
                navigate(`/checkout/payment/buy-now/${product_id}/${color}/${qty}/${pickup_id}`, { state: findAddress });
            }else{
                navigate('/checkout/payment', { state: findAddress });
            }
            
        } else {
            toast.error('Please add your address to proceed');
            return;
        }
    }
    return (
        <div>
            <main>
                <PageTitle name={'Shipping Address'} />
                <section className='checkout d-flex justify-content-center bg-white'>
                    <div className="container">
                        <div className="row">
                            <div className="delivery-header d-flex justify-content-between mt-4">
                                <div className="instruction">
                                    <h5>Delivery Charges </h5>
                                    <p>We will deliver your order to this address.</p>
                                </div>
                            </div>
                            <div className='address-add-link mt-4'>
                                <span onClick={() => setShowAddress(true)}
                                    style={{ cursor: 'pointer', backgroundColor: '#3f59a3', padding: '7px', borderRadius: '5px', color: 'white' }}>
                                    <i className="fa-solid fa-plus "></i> Add New Address
                                </span>
                            </div>
                            <div className="col-md-8 my-4">
                                <div className="second-step">

                                    <div className="row mt-4">
                                        {userAddress.map((address, i) => (
                                            <div key={i} className="col-md-6 mb-3">
                                                <div className={`card ${selectedAddressId === address?.id ? 'address-border' : ''}`} style={{ cursor: 'pointer' }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between">
                                                            <div className='d-flex justify-content-around overflow-hidden'>
                                                                <h5 className="card-title checkout-card-title w-100">
                                                                    {address?.customer_name || userData?.name}
                                                                </h5>
                                                                {selectedAddressId === address?.id && (
                                                                    <p className='badge address-badge px-3'>Primary</p>
                                                                )}
                                                            </div>
                                                            <input type="radio"
                                                                className='checkout-radio'
                                                                checked={selectedAddressId === address?.id}
                                                                onChange={() => handleDefault(address)}
                                                                style={{ width: '15px' }} readOnly />
                                                        </div>
                                                        <p className="card-text mt-2"><b>Address:</b> {address?.address}</p>
                                                        <p className="card-text"> {address?.postal_code}</p>
                                                        <p className="card-text">{address.city}, {address?.state}, {address?.country}</p>
                                                        <p className="card-text"><b>Phone Number:</b> {address?.phone_code}-{address?.phone}</p>
                                                        <p className="card-text pb-3"><b>Email:</b> {userData?.email}</p>
                                                        <span style={{ backgroundColor: '#ff8c00', padding: '4px 9px', borderRadius: '5px', color: 'white' }}>
                                                            {address?.address_type}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className="d-flex justify-content-between mb-3">
                                        <button className='checkout-back-btn' onClick={() => navigate('/cart')}><MoveLeft /> Previous</button>
                                        <button className='checkout-proceed-btn'>Proceed <MoveRight /> </button>
                                    </div> */}
                                </div>
                            </div>
                            <CheckOutSummary buyNow={method=="buy-now"?true:false} />
                        </div>
                    </div>
                </section>

                { method=="buy-now" ? 
                    <section className='checkout d-flex justify-content-center bg-white'>
                        <div className="container">
                            <div className='row'>
                                    <div className='col-md-3 col-sm-12' >
                                        <div className="card mb-3 rounded-3" style={{ maxWidth: 300, border: '1px solid #cccccc' }}>
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <div className='cart-img-div' >
                                                        <img src={item?.cart_item?.[0]?.photos?.[0]?.path} alt="buy-now-product" className="img-fluid rounded-start-3" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body cart-product-text">
                                                        <p className="card-text text-muted mb-2">
                                                            {item?.cart_item?.[0]?.name?.length > 30 ? `${item?.cart_item?.[0]?.name?.slice(0, 30)}...` : item?.cart_item?.[0]?.name}
                                                        </p>
                                                        <p className="card-text">
                                                            <small className="text-success">{item?.cart_item?.[0]?.tags}</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            
                            </div>
                        </div>
                    </section>

                    :
                    <section className='checkout d-flex justify-content-center bg-white'>
                        <div className="container">
                            <div className='row'>
                                {cart?.cart_item?.map((e, i) =>
                                    <div className='col-md-3 col-sm-12' key={i}>
                                        <div className="card mb-3 rounded-3" style={{ maxWidth: 300, border: '1px solid #cccccc' }}>
                                            <div className="row g-0">
                                                <div className="col-md-4">
                                                    <div className='cart-img-div' >
                                                        <img src={e?.photos[0]} alt={`cart-${i}`} className="img-fluid rounded-start-3" />
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body cart-product-text">
                                                        <p className="card-text text-muted mb-2">
                                                            {e?.product_name?.length > 30 ? `${e?.product_name?.slice(0, 30)}...` : e.product_name}
                                                        </p>
                                                        <p className="card-text">
                                                            <small className="text-success">{e?.tags}</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
            }
                <section className='d-flex justify-content-center bg-white'>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-3">
                            <button className='checkout-back-btn' onClick={() => navigate('/cart')}><MoveLeft /> Previous</button>
                            <button className='checkout-proceed-btn' onClick={handleNextStep}>Proceed <MoveRight /> </button>
                        </div>
                    </div>
                </section>

            </main>
            <AddressForm
                show={showAddress}
                onHide={() => setShowAddress(false)} />
        </div>
    )
}

export default ShippingAddress