import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderTracking } from '../../middlewares/cart/order'
import { getOrderTrackReset } from '../../slices/orderSlice'

const OrderDetails = () => {
    const location = useLocation();
    const data = location.state
    const dispatch = useDispatch();
    const IsLogedin = localStorage.getItem('IN_USER_DATA');

    useEffect(() => {
        !IsLogedin ? navigate('/') : <></>;
    }, [IsLogedin]);
    const navigate = useNavigate();
    const { getOrderTrack, getOrderTrackError, getOrderTrackLoading, getOrderTrackMessage } = useSelector((state) => state?.order);
    const productList = getOrderTrack?.order?.orderDetails;
    useEffect(() => {
        if (data?.Id) {
            const bodyFormData = new FormData();
            bodyFormData.append("id", data?.Id);
            dispatch(getOrderTracking(bodyFormData))
        }
    }, [data?.Id]);

    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Products Details</h3>
                    </div>
                </div>
            </section>
            <section className="profile-section profile-wrapper">
                <section className='order-view'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12">
                                <div className="order-view-left">
                                    <div className='order-view-price-detail'>
                                        <h6>Price Details</h6>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-6">
                                            <div className="item-price-left">
                                                <p>Price ( {productList?.length} item ) </p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="item-price-right">
                                                <p>{getOrderTrack?.order?.order_summary?.sub_total}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-6">
                                            <div className="item-Discount item-price-left">
                                                <p>Discount</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="item-Discount item-price-right">
                                                <p>− {getOrderTrack?.order?.order_summary?.total_discount}%</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-6">
                                            <div className="item-price item-price-left">
                                                <p>Delivery Charges</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="item-Discount item-price-right">
                                                <p>FREE</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-100">
                                        <div className="col-6">
                                            <div className="item-price item-price-left">
                                                <p>Taxes</p>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="item-price-right">
                                                <p>+ {getOrderTrack?.order?.order_summary?.tax}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price-details-total-amount">
                                        <div className="row w-100">
                                            <div className="col-6">
                                                <div className="price-detail-content">
                                                    <h6>Total Amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="price-detail-content">
                                                    <h6 className='text-right'>{getOrderTrack?.order?.order_summary?.grand_total}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <p><small>You will save ₹3,802.5 on this order</small></p> */}
                                </div>
                            </div>
                            <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12">
                                <div className="order-view-right">
                                    <nav>
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item active"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                            <li className="breadcrumb-item"><Link to="/user-orders">Orders</Link></li>
                                            <li className="breadcrumb-item"><img src="/assets/images/svg-img/arrow-right.svg" alt="arrow-right" /></li>
                                            <li className="breadcrumb-item" aria-current="page">Order Details</li>
                                        </ol>
                                    </nav>
                                </div>
                                <div className="order-view-right-detail-box">
                                    {productList?.map((e, i) =>
                                        <div className="order-view-right-detail-content active" key={i}>
                                            <div className="row">
                                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                                    <div className="order-view-right-img-box">
                                                        <div className="order-images">
                                                            <img src={e?.product?.photos[0]?.path} alt="product-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
                                                    <div className="order-detail-product">
                                                        <div className="row pb-2 align-items-center">
                                                            <div className="col-xl-9 col-lg-9 col-md-6 col-6">
                                                                <div className="order-detail-product-title">
                                                                    <h6>{e?.product?.brand_name}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-6 col-6">
                                                                <div className="order-detail-track mt-md-2 mt-sm-2">
                                                                    <h6 className='text-end' onClick={() => navigate('/track-order', { state: { pro_id: e?.product?.id, order_id: getOrderTrack?.order?.id } })} style={{ cursor: 'pointer' }}>Track Order</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>{e?.product?.name}</p>
                                                        <div className='order-delivery mt-md-2 '>
                                                            <h6 className='delivery-date'><img src="/assets/images/svg-img/truck-tick.svg" alt="truck-tick" /> Estimated delivery: {getOrderTrack?.estimated_delivery ? `Estimated delivery: ${getOrderTrack?.estimated_delivery}` : "No estimated delivery available"}</h6>
                                                        </div>
                                                        <h5><span>₹ {e?.price}</span> </h5>
                                                        <p className='mt-2' style={{ color: "#00c53c" }}><b> Quantity: </b><span>{e?.quantity}</span> </p>
                                                        {/* <div className="row">
                                                                <div className="col-xl-2 col-lg-3 col-md-6 col-6">
                                                                    <div className='d-flex order-detail-add-product'>
                                                                        <button><i class="fa-solid fa-minus"></i></button>
                                                                        <input type="number" />
                                                                        <button><i class="fa-solid fa-plus"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section >

        </>
    )
}

export default OrderDetails