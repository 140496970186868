import Pagination from "react-bootstrap/Pagination";

const OrderPagination = ({
    prev,
    prevDisabled = false,
    next,
    nextDisabled = false,
    currentPage,
    totalPages,
    handlePageClick, // New prop for handling page number clicks
}) => {

    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    const pages = [];

    for (let i = startPage; i <= endPage; i++) {
        pages.push(
            <Pagination.Item
                key={i}
                active={i === currentPage}
                onClick={() => {
                    if (i !== currentPage) handlePageClick(i); 
                }}
                style={{
                    backgroundColor: i === currentPage ? '#007bff' : '#ffffff',
                    color: i === currentPage ? '#ffffff' : '#000000',
                }}
            >
                {i}
            </Pagination.Item>
        );
    }

    return (
        <Pagination size="sm">
            <Pagination.Prev onClick={prev} disabled={prevDisabled} />
            {pages}
            <Pagination.Next onClick={next} disabled={nextDisabled} />
        </Pagination>
    );
};

export default OrderPagination;
