import React from 'react'
import { Link } from 'react-router-dom'

const Compliance = () => {
    return (
        <>
            <section className="page-title">
                <div className="pattern-layer"></div>
                <div className="container-full">
                    <div className="content-box">
                        <h3>Compliance</h3>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item active" aria-current="page"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item" aria-current="page">/</li>
                                <li className="breadcrumb-item" aria-current="page">Compliance</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </section>
            <section className="section-b-space layout-light">

                <div className="custom-container container">
                    <div className="logo-compliance">
                        <div className="logo-inner-compliance">
                            <Link
                                className="navbar-brand  d-md-block d-lg-block d-none "
                                to="/"
                            >
                                <img src="/assets/images/svg-img/logo.svg" alt="Logo" />
                            </Link >
                        </div>
                    </div>
                    <div className="row gy-4">
                        <div className="col-12 mb-0 px-4">
                            <div className="about-title ">
                                <h6 className='text-center mb-3'>
                                    (UNDERTAKING)
                                    <p>* * *</p>
                                </h6>
                                <ul >
                                    <li className='mb-2'> <strong>I</strong> <strong style={{ "text-decoration": "underline" }}>Bhawna Pagaria , Partner</strong> at <strong style={{ "text-decoration": "underline" }}>Mahakali Infotek</strong> (&ldquo;Indiazona&rdquo;), do hereby confirm and declare that:<p>Indiazona is a e-commerce Platform and in no way is or promote a Pyramid Scheme, nor does it enroll any</p> </li>
                                    <p className='mb-2'>Indiazona is a e-commerce Platform and in no way is or promote a Pyramid Scheme, nor does it enroll any
                                        person who participates in such a scheme/arrangement in any manner whatsoever.</p>
                                    <p className='mb-2'>Indiazona doesn’t charge any vendor/partner onboarding fees of any manner.</p>
                                    <p className='mb-2'>IndiaZona does not subject its buyers to any inflated product prices and that the prices of the products are solely
                                        decided by the Vendors who list their products on the Platform</p>
                                    <p className='mb-2'>Likewise, Indiazona does not participate in any Money Circulation Scheme, nor does it enroll anyperson who
                                        participates in such a scheme/arrangement in any manner whatsoever.</p>
                                    <p className='mb-2'>Indiazona is compliant with the provisions of the Consumer Protection (Direct Selling) Rules, 2021promulgated
                                        vide No. G.S.R. 889(E) by Department of Consumer Affairs.</p>


                                </ul>

                                <section className='mt-3'>
                                    <div className="container">
                                        <div className="row justify-content-between">
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className='place-wpr'>
                                                    <p>Place: Kolkata</p>
                                                    <p>Date: 30/08/24 </p>
                                                </div>

                                            </div>
                                            <div className="col-lg-4 col-md-5 col-sm-12">
                                                <div className='name-wpr'>
                                                    <p> Name: Bhawna Pagaria</p>
                                                    <p> Designation: Partner</p>
                                                    <p>Mail -Id:<a data-fr-linked="true" href="mailto:info@indiazona.com">info@indiazona.com</a></p>
                                                    <p>Mobile: 9748530006</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section style={{ borderTop: '1px solid blue' }} className='mt-4 py-2'>
                                    <p>Registered Office: Room No. 1B/3, 2nd Floor,11 Clive Row, Dalhousie Square, Kolkata, West Bengal</p>
                                    <p className='d-flex justify-content-between mt-2'><p>Website: <a data-fr-linked="true" href="//www.indiazona.in">www.indiazona.in</a></p> Follow us: Instagram | Facebook | X | Linkedin</p>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Compliance