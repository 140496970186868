import estimatedService from "../services/estimatedOrder";
import { estimatedError, estimatedRequest, estimatedSuccess } from "../slices/estimatedOrder";

export function getEstimatedOrder(payload) {
    return (dispatch) => {
        dispatch(estimatedRequest());
        estimatedService.estimatedOrder(payload)
            .then((response) => {
                const { status, data, message, error } = response.data;
                if (status === 1) {
                    dispatch(estimatedSuccess({ data, message }));
                } else {
                    dispatch(estimatedError({ error }));
                }
            }).catch((error) => {
                dispatch(estimatedError(error));
            })
    }
}
