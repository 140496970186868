import { postFormData, post } from ".";

const URI = "/products";

const estimatedOrder = (payload) => {
    const URL = `${URI}/estimated-order-delivery-date`;
    return post(URL, payload);
};

const estimatedService = {
    estimatedOrder,
}
export default estimatedService;

