import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import {
    acountHoldername,
    accountNo,
    IFSCcode,
    cAccountNo
} from "../../../helpers/yup.validation.schema";

const AddBankDetailsModal = ({ show, setModalOpen, handleSubmit }) => {

    useEffect(() => {
        if (show) {
            formik.resetForm();
        }
    }, [show])

    const AccountSchema = Yup.object({
        name: acountHoldername,
        accountNo: accountNo,
        cAccountNo: cAccountNo,
        IFSCcode: IFSCcode
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            accountNo: "",
            cAccountNo: "",
            IFSCcode: "",
        },
        validationSchema: AccountSchema,
        onSubmit: handleSubmit,
    });
    const handleModalClose = () => {
        formik.resetForm();
        setModalOpen(false);
    };
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            centered
            size="lg"
            keyboard={false}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <form className='add-bank-form' onSubmit={formik.handleSubmit}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-2">
                                    <label htmlFor="Beneficiary" className="form-label">Beneficiary name</label>
                                    <input
                                        type="text"
                                        className="form-control add-bank-form-control"
                                        name='name'
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        placeholder='Enter your name here'
                                    />
                                    <div className="error pt-0">{formik.errors.name}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-2">
                                    <label htmlFor="Account-Number" className="form-label">Account Number</label>
                                    <input
                                        type="number"
                                        name='accountNo'
                                        value={formik.values.accountNo}
                                        onChange={formik.handleChange}
                                        className="form-control add-bank-form-control"
                                        placeholder='Enter Your Account Number here'
                                    />
                                    <div className="error pt-0">{formik.errors.accountNo}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-2">
                                    <label htmlFor="Re-enter-your" className="form-label">Re-enter your Account Number</label>
                                    <input
                                        type="number"
                                        name='cAccountNo'
                                        value={formik.values.cAccountNo}
                                        onChange={formik.handleChange}
                                        className="form-control add-bank-form-control"
                                        placeholder='Enter Your Account Number here'
                                    />
                                    <div className="error pt-0">{formik.errors.cAccountNo}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-2">
                                    <label htmlFor="IFSC-Code" className="form-label">IFSC Code</label>
                                    <input
                                        type="text"
                                        name='IFSCcode'
                                        value={formik.values.IFSCcode}
                                        onChange={formik.handleChange}
                                        className="form-control add-bank-form-control"
                                        placeholder='Enter Your IFSC Code'
                                    />
                                    <div className="error pt-0">{formik.errors.IFSCcode}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-3 col-6">
                                <button className="btn w-100 add-bank-verify-btn" type='submit'>Verify</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default AddBankDetailsModal;
