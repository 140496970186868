import React, { useEffect, useState } from 'react'
import PageTitle from '../../Components/Products/PageTitle';
import { Tab, Nav, Row, Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { generateBuyNowOrder, generateOrder } from '../../middlewares/cart/order';
import LocalstorageService from '../../helpers/localstorage-services';
import { getCartData } from '../../middlewares/cart/cart';
import { fetchAddress } from '../../middlewares/UserProfile/address';
import { MoveLeft } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { createOrderReset } from '../../slices/orderSlice';
import CheckOutSummary from '../../Components/Products/CheckOut/CheckOutSummary';
import ROUTE_URLS from '../../config/routes';
import { IN_URL } from '../../BaseUrl';
import axios from 'axios';
import { buyNowAddProduct } from '../../slices/buyNowSlice';
import { toast } from 'react-toastify';

const PaymentPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { buyOption,product_id,color,qty,pickup_id } = useParams();
    const data = location.state

    const userData = LocalstorageService.getLoggedInUserDetails();
    const { cart, cartError, cartLoading, cartMessage } = useSelector((state) => state.cart);
    const { userAddress, getAddressError, getAddressLoading, getAddressMessage, } = useSelector((state) => state.address);
    const { createOrderLoading, createOrder, createOrderMessage, createOrderError } = useSelector((state) => state.order);
    const { productDetails, productDetailsLoading, } = useSelector(state => state.product);
    const {item}=useSelector(state => state.buyNow);

    useEffect(() => {
        if (!userData?.id) {
            navigate(ROUTE_URLS.HOME, { replace: true });
        }
    }, [cart]);

    useEffect(() => {
        const bodyFormData = new FormData();
        bodyFormData.append("user_id", userData?.id);
        bodyFormData.append("customer_pincode", data?.postal_code);
        bodyFormData.append("is_checkout", 1);
        dispatch(getCartData(bodyFormData));
        dispatch(fetchAddress());

        if(buyOption ==="buy-now" && item.length===0){
            const newFormData = new FormData();
            newFormData.append('user_id',userData?.id);
            newFormData.append('product_id',product_id);
            newFormData.append('quantity',qty);
            newFormData.append('color',color);
            newFormData.append('pickup_addresse_id',pickup_id);
            
            const url=IN_URL +'/cart/buy-now';
            let responseData="";
            const config = {};
            axios
              .post(url, newFormData,{
                "Content-Type": "multipart/form-data",
                ...config,
              })
              .then((response) => {
                responseData= response.data; // Handle successful response
                const cart_item=[productDetails];
                // const data={...responseData,cart_item};
                dispatch(buyNowAddProduct({...responseData,cart_item}));
                
                // navigate(`/checkout/shipping/buy-now`);
                // setError(null); // Clear any previous errors
              })
              .catch((err) => {
                responseData=err.message; // Handle error response
                console.error("Error submitting form data:", err);
                toast.error("Something went wrong! Please try again later");
              });

            }


    }, []);

    useEffect(() => {
        if (createOrderMessage) {
            navigate('/user-orders', { state: true });
        } else if (createOrderError) {
            Swal.fire('Error', 'There was an issue placing your order. Please try again.', 'error');
        }
    }, [createOrderMessage]);

    const handlePayment = (method) => {
        const cartIds = cart?.cart_item?.map(item => ({ id: item.id }));
        // const itemId=[{id:item.product_id}]
        
        // console.log(cartIds);
        const bodyFormData = new FormData();
        bodyFormData.append('address_id', userAddress?.find((add) => add?.set_default === 1)?.id)
        bodyFormData.append('user_id', userData?.id);
        bodyFormData.append("is_checkout", 1);
        if(buyOption==="buy-now"){
            bodyFormData.append('product_id', item.product_id);
            bodyFormData.append('color', color);
            bodyFormData.append('quantity', qty);
            // console.log("itemId: ",item.product_id);
        }
        else{
            bodyFormData.append('cart_id', JSON.stringify(cartIds));
        }
        bodyFormData.append('payment_type', method);
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to place the order?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, place order!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                if(buyOption==="buy-now")dispatch(generateBuyNowOrder(bodyFormData));
                else{
                dispatch(generateOrder(bodyFormData));
                }
            }
        });
    }

    return (
        <main>
            <PageTitle name={'Payment Method'} />
            <section className='checkout d-flex justify-content-center bg-white py-3 payment-page'>
                <div className="container">
                    <div className="row align-items-center">
                        <h3 className=''>Select Payment Method</h3>
                        <div className='col-12 col-md-8'>
                            <Tab.Container id="payment-tabs" defaultActiveKey="cashOnDelivery">
                                <Row className="d-flex align-items-start">
                                    <Col sm={4} className='payment-type'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="cashOnDelivery" className='payment-text'>
                                                    Cash On Delivery
                                                </Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item>
                                                <Nav.Link eventKey="netBanking" className='payment-text' disabled>Net Banking</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="wallet" className='payment-text' disabled>Wallet</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="upi" className='payment-text' disabled>UPI</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </Col>
                                    <Col sm={1} className='payment-gap' />
                                    <Col sm={7} className='payment-section'>
                                        <div className='row'>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="cashOnDelivery">
                                                    <div className='row'>
                                                        <div className='card border-0'>
                                                            <h5 className='mb-2'>Cash On Delivery</h5>
                                                            <div className='mb-2'>
                                                                <p>Experience hassle-free shopping with IndiaZona. Select Cash on Delivery at checkout and pay securely upon receiving your order.</p>
                                                            </div>
                                                            <div className='mt-2'>
                                                                <button className='btn payment-btn w-50' type='button' onClick={() => handlePayment('cash_on_delivery')} disabled={createOrderLoading}>
                                                                    {createOrderLoading ? 'Processing...' : 'PLACE ORDER'}
                                                                </button>
                                                            </div>
                                                            <p className='mt-2'><small>By placing this order, you agree to Indiazona T&C</small></p>
                                                        </div>
                                                    </div>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="netBanking">
                                                    <p>Net Banking content goes here...</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="wallet">
                                                    <p>Wallet content goes here...</p>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="upi">
                                                    <p>UPI content goes here...</p>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                        <CheckOutSummary buyNow={buyOption==="buy-now"?true:false}/>
                    </div>
                </div>
            </section>

            <section className='d-flex justify-content-center bg-white'>
                <div className='container'>
                    <div className="d-flex justify-content-between mb-3">
                        <button className='checkout-back-btn' onClick={() => navigate('/checkout/shipping', { state: data?.postal_code })}><MoveLeft /> Previous</button>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default PaymentPage