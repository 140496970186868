import { createSlice } from "@reduxjs/toolkit";
import { SEARCH_ON_SUBMIT } from "../helpers/slice.name";

export const searchOnSubmitSlice = createSlice({
    name: SEARCH_ON_SUBMIT,
    initialState: {
        searchItems: [],
        errors:"",
        itemsLoading:false,
    },
    reducers: {
        setSearchItems: (state, action) => {
            state.itemsLoading=false;
            state.searchItems = action.payload;
        },
        clearSearchItems:(state, action) => {
            state.searchItems =[];
        },
        setSearchErrors:(state, action) => {
            state.itemsLoading=false;
            state.searchItems =[];
            state.errors=action.payload;
        },
        setSearchLoadingTrue:(state)=>{
            state.itemsLoading=true;
        },
        setSearchLoadingFalse:(state)=>{
            state.itemsLoading=false;
        },
    }
});

export const { setSearchItems,clearSearchItems,setSearchErrors,setSearchLoadingTrue,setSearchLoadingFalse } = searchOnSubmitSlice.actions;

export default searchOnSubmitSlice.reducer;
