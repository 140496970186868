import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

const BlogPage = () => {
    const navigate = useNavigate()
    const doodlesData = useSelector((state) => (state.doodle.doodles));
    return (
        <>
            <main>
                <section className="page-title">
                    <div className="pattern-layer"></div>
                    <div className="container-full">
                        <div className="content-box">
                            <h3>Blogs</h3>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item active" aria-current="page"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item" aria-current="page">/</li>
                                    <li className="breadcrumb-item" aria-current="page">Blog Page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="article-section">
                    <div className="container-fluid">
                        <div className="row">
                            {
                                doodlesData.map(elem =>
                                    <div className="col-xl-3 col-md-4 col-12">
                                        <div className="blog-article-item">
                                            <div className="article-thumb">
                                                <img className="lazyload" src={`${elem?.header_image}`} onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }} alt="img-blog" />
                                                <div className="article-label">
                                                    <span onClick={() => navigate('/blog-details', { state: elem })} style={{ cursor: 'pointer' }} className="tf-btn btn-sm radius-3 btn-fill animate-hover-btn">Accessories</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="article-content">
                                            <div className="article-title">
                                                <span onClick={() => navigate('/blog-details', { state: elem  })} style={{ cursor: 'pointer' }} className>{elem?.title}</span>
                                            </div>
                                            <div className="article-btn">

                                                <span onClick={() => navigate('/blog-details', { state: elem  })} style={{ cursor: 'pointer' }} className="tf-btn btn-line fw-6">Read more<i className="icon fa-solid fa-arrow-right px-1" /></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>

                        <div className="d-flex justify-content-center">
                            <nav aria-label="Table Pagination">
                                <ul className="pagination style-1">
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">Prew</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">1</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">2</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">3</a></li>
                                    <li className="page-item"><a className="page-link" href="javascript:void(0);">Next</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="newest-section">
                    <div className="container">
                        {/* <div className="authors-section">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h6 className="blog-title">Newest blog</h6>
                                    <p className="blog-disc">Say hello to future creator potentials</p>
                                </div>
                            </div>
                            <div className="newest-wrapper">
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/profile-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Write</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href="./blog-detail.html">
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-12">
                                        <a href>
                                            <div className="authors-card">
                                                <div className="authors-bg-img">
                                                    <img src="/assets/images/author/author-bg.png" />
                                                </div>
                                                <div className="authors-img">
                                                    <img src="/assets/images/author/author-1.jpg" alt="author-img" />
                                                </div>
                                                <div className="authors-name">
                                                    <h5 className="text-center">Bhavik Sarkhedi</h5>
                                                    <p className="text-center">@ Authors</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>

            </main>
        </>
    )
}

export default BlogPage