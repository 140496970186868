import React from 'react'
import { Link } from 'react-router-dom'
import './landingpage.css'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { SELLER_ADD, SELLER_LOGIN } from '../../BaseUrl'
// import { BsFillTelephoneFill } from "react-icons/bs";
const LandingPage = () => {
  return (
    <>
    <div className="w-100 m-0 pb-3" style={{backgroundColor:"#FFF8F0"}}>
      <nav className="landing-header">
        <div className="full-width container-xxl ">
          <div className="row align-items-center justify-center header-container" >
            <div className="col-lg-6 col-md-6 col-6">
              <div className="header-logo py-2">
                <div className="logo-inner">
                  <Link className="navbar-brand d-md-block d-lg-block " to="/home">
                    <img src="/assets/images/svg-img/logo.svg" alt="Logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-6">
              <div className="social-icon">
                <ul className="social_link list-inline mb-0 h-100 d-flex align-items-center">
                  <p className='d-lg-block d-md-block d-none'>Follow @indiazonafamily</p>
                  <li><a href="https://www.facebook.com/indiazonafamily" target='_blank'><i className="fa-brands fa-facebook" style={{ color: "#1c67fa", fontSize: '20px' }} /></a></li>
                  <li><a href="https://x.com/indiazonafamily" target='_blank'><i className="fa-brands fa-x-twitter" style={{ color: "#0c0d0d", fontSize: '20px' }} /></a></li>
                  <li><a href="https://www.instagram.com/indiazonafamily/" target='_blank'><img src='/assets/images/svg-img/instagram.svg' /></a></li>
                  {/* <li><a href="#"><i className="fa-brands fa-youtube" style={{ color: "#ff131b", fontSize: '20px' }}></i> </a></li> */}
                  <li><a href="https://www.linkedin.com/company/indiazonafamily/" target='_blank'><i className="fa-brands fa-linkedin" style={{ color: "#1467be", fontSize: '20px' }} /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <section className="landing-banner">
        <div className="container">
          <div className="row hero">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="landing-banner-inner">
                <h1>Unlock new growth opportunities by showcasing your products on Bharat Ki Apni Dukaan. <span style={{color:"#FF8C00",fontWeight:"700"}}>Join Indiazona Now!</span></h1>
                <h1 className='mt-4'><span style={{color:"#42B649",fontWeight:"700"}}>Vendor Registration in Progress.</span></h1>
              </div>
            </div>


            <div className="col-lg-12 banner-button">
{/*               
              <a class="btn w-100" href="/benifits" role="button" style={{color:"#FF8C00"}}>Benefits of becoming our Vendor</a>
              <a class="btn " href={SELLER_ADD} role="button" style={{background:"#FF8C00",color:"white"}}>Become our Vendor</a> */}
              <div className="row w-100">
                <div className="col-lg col-sm-12 my-2"><a class="btn w-100" href={SELLER_LOGIN}  style={{color:"#FF8C00"}} target="_blank">Login to Vendor</a></div>
                <div className="col-lg-5 col-sm-12 my-2"><a class="btn w-100" href="/benifits" role="button" style={{color:"#FF8C00"}} target="_blank" >Benefits of becoming our Vendor</a></div>
                <div className="col-lg col-sm-12 my-2"><a class="btn w-100" href={SELLER_ADD} role="button" style={{background:"#FF8C00",color:"white"}} target="_blank" >Become our Vendor</a></div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <div className="container-xxl mt-5 h-100" >
        <div className="row d-flex justify-content-between" >
          <div className="col-lg-5 col-sm-12 px-4 card-about " >
            <h5 className='mt-2' >About Indiazona</h5>
            <p style={{textAlign:"left",fontSize:"18px"}} className='mt-2'> Indiazona - Bharat ki Apni Dukaan is a pivot to empower Indian Businesses, an E-Commerce marketplace bringing Made in India, Handmade & Sustainable products to the forefront.</p>
          </div>
          <div className="col-lg-5  col-sm-12   card-contact">
            <h5  className='mt-2' >Contact Us</h5>
            <div className="row w-75 text-start">
              <div className="col-5 col-sm-12 col-xs-12 mt-2" ><a href="tel:+91 8374320725"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
</svg>+91 8374320725</a></div>
              <div className="col-5 col-sm-12 col-xs-12 mt-2"><a href="mailto:care@indiazona.com"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
</svg>care@indiazona.com</a></div>
              <div className="col-12 col-sm-12 col-xs-12 mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill me-2" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg>11, Clive Row, 4th Floor,Kolkata, India - 700001</div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">

          </div>
        </div>
      </div>

      <div className="container-xxl container-launching mt-5 mb-5">
        <div className="row h-100 ">
          <div className="col-lg-12 h-100" >
            <div className="row card-launching h-100 px-3">
              <div className="col-lg-6 col-md-12 ">
                <h5>Launching Soon for Customers!</h5>
                <p>Excited to shop from Bharat Ki Apni Dukaan? We know the wait is hard, but it will be over soon! Meanwhile, take a sneak peek at our demo website.</p>
                <a class="btn mt-2" href="/home" role="button" style={{color:"#FF8C00",fontSize:"22px"}}>Website Preview</a>
              </div>
              <div className="col-lg-6 col-md-12 landing-pg-img text-center">
              <img src="/assets/images/landing-page/landing-page-image.svg" alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default LandingPage