import { createSlice } from "@reduxjs/toolkit";
import { ACCOUNTDETAILS } from '../helpers/slice.name';

const accountSlice = createSlice({
    name: ACCOUNTDETAILS,
    initialState: {

        accountLoading: false,
        accountOrder: {},
        accountMessage: '',
        accountError: '',
    },
    reducers: {
        accountRequest: (state, action) => {
            state.accountLoading = true
            state.accountMessage = ''
            state.accountError = ''
        },
        accountSuccess: (state, action) => {
            state.accountLoading = false
            state.accountOrder = action.payload.data
            state.accountMessage = action.payload.message
        },
        accountError: (state, action) => {
            state.accountLoading = false
            state.accountError = action.payload.error

        },
        accountReset: (state, action) => {
            state.accountMessage = ''
            state.accountError = ''
        },
    }
})

export const {
    accountRequest,
    accountSuccess,
    accountError,
    accountReset,

} = accountSlice.actions
export default accountSlice.reducer;